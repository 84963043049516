import { Directive, HostListener, HostBinding, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[fileDragDrop]',
  standalone: true,
})

export class FileDragNDropDirective {
  @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  @HostBinding('style.background') private background = '#fff';
  @HostBinding('style.border') private borderStyle = '1px dashed';
  @HostBinding('style.border-color') private borderColor = ' #cbd4e1';
  @HostBinding('style.border-radius') private borderRadius = '8px';

  @HostListener('dragover', ['$event']) public onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.updateStyles('#fff', '#008ECF', '1px dashed');
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.handleDragLeave();
  }

  @HostListener('drop', ['$event']) public onDrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.updateStyles('#fff', '#DBE3ED', '1px dashed');
    let files = evt.dataTransfer.files;
    let valid_files: Array<File> = files;
    this.filesChangeEmiter.emit(valid_files);
  }

  private updateStyles(background: string, borderColor: string, borderStyle: string): void {
    this.background = background;
    this.borderColor = borderColor;
    this.borderStyle = borderStyle;
  }

  private handleDragLeave(): void {
    this.updateStyles('#fff', '#008ECF', '1px dashed');
  }
}
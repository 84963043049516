export const Toasts = {
    Types: {
        Error: 'Error',
        Success: 'Success',
        Information: 'Information',
        Warning: 'Warning',
    },
    Actions: {
        Snooze: {
            Title: 'Notification Snoozed',
            Error: `Alert couldn't be snoozed. Please try again.`,
        },
        Publish: {
            Title: 'CCD published',
            Success: 'has been published successfully.',
            Error: `This CCD couldn't be published. Please try again.`,
        },
        Cancel: {
            Title: 'CCD cancelled',
            Success: 'has been cancelled successfully.',
            Error: `This CCD couldn't be cancelled. Please try again.`,
        },
        Pursue: {
            Title: 'CCD pursued',
            WarningTitle: 'CCD Locked',
            Success: 'has been pursued successfully.',
            Error: `This CCD couldn't be pursued. Please try again.`,
        },
        Initiate: {
            Title: 'CCD initiated',
            WarningTitle: 'CCD Locked',
            Success: 'has been initiated successfully.',
            Error: `This CCD couldn't be initiated. Please try again.`,
        },
        Reactivate: {
            Title: 'CCD reactivated',
            WarningTitle: 'CCD Locked',
            Success: 'has been reactivated successfully.',
            Error: `This CCD couldn't be reactivated. Please try again.`,
        },
        DeleteCcd: {
            Title: 'Draft CCD deleted',
            Success: 'Draft CCD# has been deleted successfully.',
            Error: `This draft CCD couldn't be deleted. Please try again.`,
        },
        Submit: {
            Title: 'CCD submitted',
            Success: 'has been sent to the selected departments successfully.',
            Error: `This draft CCD couldn't be submitted. Please try again.`,
        },
        AutoSave: {
            Title: 'Draft CCD saved',
            Success: 'Your progress has been saved as a draft.',
            Error: `Your progress couldn't be saved. Please try again.`
        },
        SaveAsDraft: {
            Title: 'Draft CCD saved',
            Success: 'CCD# is saved as draft',
            Error: `This CCD couldn't be saved as draft. Please try again.`
        },
        DeleteDocument: {
            Title: 'Document deleted',
            Success: 'Document deleted successfully.',
            Error: `This document couldn't be deleted. Please try again.`,
        },
        DownloadDocument: {
            Title: 'Document downloaded',
            Error: `This document couldn't be downloaded. Please try again.`,
        },
        MarkComplete: {
            Title: 'Mark as complete',
            WarningTitle: 'Mark as complete Locked'
        },
        GeneralError: {
            Title: 'Error: Action failed',
            Error: `Please try again. If the error continues, please contact support`
        },
        AddUser: {
            Title: 'User added',
            Success: 'added as CCD user successfully.'
        },
        UpdateUser: {
            Title: 'User details updated',
            Success: 'details has been updated.'
        },
        DeleteUser: {
            Title: 'User removed',
            Success: 'has been removed as a CCD user.'
        },
    }
}

export const customizedMessages = (type: string, ccdNo: string, customer = '') => {
    let message = '';

    switch (type) {
        case 'publish':
            message = `CCD# ${ccdNo} ${Toasts.Actions.Publish.Success}`;
            break;
        case 'cancel':
            message = `CCD# ${ccdNo} ${Toasts.Actions.Cancel.Success}`;
            break;
        case 'initiate':
            message = `CCD# ${ccdNo} ${Toasts.Actions.Initiate.Success}`;
            break;
        case 'submit':
            message = `CCD# ${ccdNo} for ${customer.toUpperCase()} ${Toasts.Actions.Submit.Success}`;
            break;
        case 'AddUser':
            message = `${customer} ${Toasts.Actions.AddUser.Success}`;
            break;
        case 'UpdateUser':
            message = `${customer} ${Toasts.Actions.UpdateUser.Success}`;
            break;
        case 'DeleteUser':
            message = `${customer} ${Toasts.Actions.DeleteUser.Success}`;
            break;
        default:
            break;
    }
    return `${message}`;
}
<snl-modal class="CommnModalChanges AddNewUserMdl" [ngClass]="isEditMode ? 'EditUserMdl' : ''" [type]="'medium'" [open]="openAddUserPopup" (modalClose)="closeModalPopUp()" [settings]="modalSettings">
    @if (isEditMode) {
    <div slot="heading">{{userDataForPopulation?.name}}</div>
    }
    @else {
    <div slot="heading">Add new user</div>
    }
    <div slot="modalContent">
      <form [formGroup]="userForm" class="AddNewWrap">
        <ul>
          @if (!isEditMode){
          <li>
            <div class="floating-input-container">
              <input type="text" id="name" class="floating-input" placeholder=" " formControlName="nameSearch" />
              <label for="name" class="floating-label body-b1">Name/ Initial</label>
              @if (userForm.get('nameSearch')?.value?.length === 0) {
                <svg-icon src="assets/icons/search_icn.svg"></svg-icon >
              }
              @else {
                <svg-icon (click)="userForm.get('nameSearch')?.setValue('')" src="assets/icons/cross_icn.svg"></svg-icon >
              }
              <div *ngIf="showSearchResult" class="DropDownCstm">
                  <ul>
                    @if(userSearchResult?.length > 0){
                    <li *ngFor="let user of userSearchResult" class="body-b1" [innerHtml]="user?.highlightedDisplayName" (click)="selectUser(user)"></li>
                    }
                    @else {
                    <li class="body-b1">No user found</li>
                    }
                  </ul>
              </div>
            </div>
          </li>
            }
            <li class="role-parent blank-role">
              @if (!userForm.value?.designation) {
              <div class="role-placeholder body-b1">
                <span>Role</span>
              </div>
              }
              @else {
              <snl-input
                [label]="'Role'"
                formControlName="designation"
                [value]="userForm.value?.designation"
              ></snl-input>
              }
            </li>
            <li>
                <snl-dropdown
                [label]="'Department'"
                [buttonPrimary]="'Apply'"
                [buttonSecondary]="'Reset'"
                [options]="divisionOptions"
                [multiple]="false"
                (selectedValues)="changeDivision($event)"
                (resetDropdown)="resetDivision()"
                [preSelectedItems]="divisionObj"
              ></snl-dropdown>
            </li>
        </ul>
        <ul>
            <li>
                <span class="section-title">Management</span>
                @if (refreshManagementOwnerSwitch) {
                <snl-switch
                [ngClass]="disableManagementSwitch ? 'disabled' : ''"
                [activeText]="'YES'"
                [inActiveText]="'NO'"
                [toggleState]="managementSwitchState"
                (toggle)="managementSwitchClick($event)"
                [disabled]="disableManagementSwitch"
                ></snl-switch>
                }
            </li>
            <li>
                <span class="section-title">Owner</span>
                @if (refreshManagementOwnerSwitch) {
                <snl-switch 
                [ngClass]="disableOwnerSwitch ? 'disabled' : ''"
                [activeText]="'YES'"
                [inActiveText]="'NO'"
                [toggleState]="ownerSwitchState"
                (toggle)="ownerSwitchClick($event)"
                [disabled]="disableOwnerSwitch"
                ></snl-switch>
                }
            </li>
            <li>
                <span class="section-title">Admin</span>
                @if (refreshAdminSwitch) {
                <snl-switch 
                [ngClass]="disableAdminSwitch ? 'disabled' : ''"
                [activeText]="'YES'"
                [inActiveText]="'NO'"
                [toggleState]="adminSwitchState"
                (toggle)="adminSwitchClick($event)"
                [disabled]="disableAdminSwitch"
                ></snl-switch>
                }
            </li>
            <li>
                <span class="section-title">Department edit</span>
                @if (refreshDepartmentViewonlySwitch) {
                <snl-switch 
                [ngClass]="disableDepartmentSwitch ? 'disabled' : ''"
                [activeText]="'YES'"
                [inActiveText]="'NO'"
                [toggleState]="departmentSwitchState"
                (toggle)="departmentSwitchClick($event)"
                [disabled]="disableDepartmentSwitch"
                ></snl-switch>
                }
            </li>
            <li>
                <span class="section-title">View only</span>
                @if (refreshDepartmentViewonlySwitch) {
                <snl-switch 
                [ngClass]="disableViewOnlySwitch ? 'disabled' : ''"
                [activeText]="'YES'"
                [inActiveText]="'NO'"
                [toggleState]="viewOnlySwitchState"
                (toggle)="viewOnlySwitchClick($event)"
                [disabled]="disableViewOnlySwitch"
                ></snl-switch>
                }
            </li>
        </ul>
      </form>
    </div> 
    <div slot="modalFooter">
      <snl-button size="large" [disabled]="userForm.invalid || submitLoading" (buttonClick)="addUser()" class="BtnSkeleton" [ngClass]="{'Stolt-Skeleton':submitLoading}">Save</snl-button>
      <snl-button size="large" type="secondary" (click)="closeModalPopUp()">Cancel</snl-button>
    </div>
</snl-modal>
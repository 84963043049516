import { Component, ViewEncapsulation } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonModule, PageTabModule, TableModule } from 'snl-complib';
import { AddUserPopupComponent } from "../../components/add-user-popup/add-user-popup.component";
import { DeleteUserPopupComponent } from "../../components/delete-user-popup/delete-user-popup.component";
import { CcdService } from 'src/app/services/ccd.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { Toasts, customizedMessages } from 'src/app/constant/toast.messages';
import { USERTABS } from 'src/app/constant/table-headings';
import { DIVISIONS } from 'src/app/constant/constant';
import { CommonModule } from '@angular/common';
import { NavigationService } from 'src/app/services/navigation.service';
import { finalize, Subject, switchMap } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';


@Component({
  selector: 'app-manage-users',
  standalone: true,
  imports: [SvgIconComponent, ButtonModule, PageTabModule, TableModule, AddUserPopupComponent, DeleteUserPopupComponent, CommonModule],
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageUsersComponent {
  public divisions: any = DIVISIONS;
  public openAddUserPopup: boolean = false;
  public openDeleteUserPopup: boolean = false;
  public selectedTab: string = 'CREATOR';
  public userTabs = USERTABS;
  public usersData: any = [];
  public usersDataLoading:boolean = false;
  public deleteUserApiLoading:boolean = false;
  public userToDelete:any = {};
  public userDataForPopulation:any = {};
  private tabSelection$ = new Subject<{ tab: string, calledFrom: string }>();

  constructor(
    private ccdService: CcdService,
    private toasterService: ToasterService,
    private navigationService: NavigationService,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    //set header data
    const headerData = {
      headerTitle: 'User management',
      breadCrumbList: []
    }
    this.navigationService.setHeaderData(headerData);
    this.setupUserFetching();
    this.selectedTab = this.storageService.getDataFromStorage('selectedUserTab') || 'CREATOR';
    this.onTabSelect(this.selectedTab, 'ngOnInit');
  }
  
  onTabSelect(tab:string, calledFrom: string = '') {
    if (calledFrom != 'ngOnInit') {
      this.selectedTab = tab;
      this.storageService.putDataInStorage('selectedUserTab', this.selectedTab);
    }
    this.tabSelection$.next({ tab, calledFrom });
  }
  setupUserFetching() {
    this.tabSelection$
      .pipe(
        switchMap(({ tab, calledFrom }) => {
          this.usersDataLoading = true;
          return this.ccdService.fetchUsersData({
            type: tab,
            includeADUsers: calledFrom === 'ngOnInit'
          }).pipe(
            finalize(() => (this.usersDataLoading = false))
          );
        })
      )
      .subscribe({
        next: ({ userData, users }: any) => {
          this.usersData = userData;
          if (users?.value?.length > 0) {
            this.ccdService.usersDataFromAd = users?.value;
          }
        },
        error: (error) => {
          this.toasterService.openToastCcd(
            Toasts.Types.Error,
            Toasts.Actions.GeneralError.Error,
            Toasts.Actions.GeneralError.Title
          );
          console.error('Error fetching users data:', error);
        }
      });
  }

  openAddUserModal(){
    this.openAddUserPopup = true;
  }
  closeAddUserModal(event:any){
    this.openAddUserPopup = false;
    this.userDataForPopulation = {};
    if (event?.isUserAdded) {
      this.tabSelection$.next({ tab: this.selectedTab, calledFrom: 'closeAddUserModal' });
    }
  }
  openEditUserModal(userDataForPopulation:{}){
    this.userDataForPopulation = userDataForPopulation;
    this.openAddUserPopup = true;
  }

  openDeleteUserModal(userToDelete:{}){
    this.openDeleteUserPopup = true;
    this.userToDelete = userToDelete;
  }
  deleteUser(event:any){
    this.openDeleteUserPopup = false;
    if (event?.isDeleteUser) {
      const reqData = {
        "type": "DELETE",
        "userData": {
            "userId": this.userToDelete?.userId,
        }
      }
      this.deleteUserApiLoading = true;
      this.ccdService.updateUser(reqData)
        .subscribe({
          next: () => {
            this.toasterService.openToastCcd(Toasts.Types.Warning, customizedMessages('DeleteUser', '', this.userToDelete?.name), Toasts.Actions.DeleteUser.Title);
            this.removeUserFromList();
            this.deleteUserApiLoading = false;
          },
          error: (error) => {
            this.deleteUserApiLoading = false;
            if (error?.status === 424) {
              this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.reason);
            } else {
              this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
            }
          }
        });
    }
  }
  removeUserFromList(){
    this.usersData = this.usersData.filter((user: any) => user?.userId !== this.userToDelete?.userId);
  }
}

import { Injectable } from '@angular/core';
import { VALIDATION_MESSAGE, customizedValidationMessages } from '../constant/constant';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  validationMessages: any = VALIDATION_MESSAGE;

  public showInputError(field: string, form: any): string {
    const control = form.get(field);
    if (!control || !control.errors) {
      return '';
    }

    for (const key in control.errors) {
      if (control.errors.hasOwnProperty(key)) {
        return this.getErrorMessage(key, control.errors, field);
      }
    }

    return '';
  }

  private getErrorMessage(key: string, errors: any, field: string): string {
    if (key === 'minlength' || key === 'maxlength') {
      return customizedValidationMessages(key, errors[key]?.requiredLength);
    }

    if (key === 'pattern' && this.isNumericField(field)) {
      return this.validationMessages['onlyNumbers'];
    }

    if (key === 'minMaxVolume') {
      return this.validationMessages['minMaxVolume'];
    }

    if (key === 'minMaxFreight') {
      return this.validationMessages['minMaxFreight'];
    }

    return this.validationMessages[key] || '';
  }

  private isNumericField(field: string): boolean {
    return [
      'estAnnualVolumeMin',
      'estAnnualVolumeMax',
      'estAnnualFreightMin',
      'estAnnualFreightMax',
    ].includes(field);
  }
}

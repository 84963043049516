import { CommonModule } from '@angular/common'; 
import { Component, OnInit, ElementRef } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ActionPanelModule, ButtonModule, CheckboxModule, DatePickerModule, DropdownModule, FileUploadModule, InputModule, LinkModule, ModalModule, PageTabModule, PageTitleModule, ProfileImageModule, SwitchModule, TooltipModule } from 'snl-complib';
import { PreviewCcdDetailsComponent } from "../../components/preview-ccd-details/preview-ccd-details.component";
import { CcdService } from 'src/app/services/ccd.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { DEPARTMENTMAPPING, DEPARTMENTTABSORDER } from 'src/app/constant/table-headings'
import { AuthService } from 'src/app/services/auth.service';
import { ROLES } from 'src/app/constant/constant';
import { Subject, forkJoin, take } from 'rxjs';
import { UploadFilesComponent } from "../../components/upload-files/upload-files.component";
import { FileUploadService } from 'src/app/services/file-upload.service';
import { HttpResponse } from '@angular/common/http';
import { ToasterService } from 'src/app/services/toaster.service';
import { Toasts } from 'src/app/constant/toast.messages';
import { NotesComponent } from 'src/app/components/notes/notes.component';
import { NotesPopupComponent } from 'src/app/components/notes-popup/notes-popup.component';

@Component({
  selector: 'app-review-and-approve',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, DropdownModule, DatePickerModule, InputModule, ButtonModule, ActionPanelModule, PageTitleModule, LinkModule, PageTabModule, SwitchModule, ModalModule, CheckboxModule, FileUploadModule, PreviewCcdDetailsComponent, ProfileImageModule, UploadFilesComponent, NotesComponent, NotesPopupComponent, TooltipModule],
  templateUrl: './review-and-approve.component.html',
  styleUrls: ['./review-and-approve.component.scss']
})
export class ReviewAndApproveComponent implements OnInit {
  public ccdNo: string | null = null;
  public ccdRes: any = {};
  public ccdNotes: any = {};
  public fetchDataLoading: boolean = false;
  public fetchCcdNotesLoading: boolean = false;
  public fetchDepartmentsLoading: boolean = false;
  public fetchCcdDocumentsLoading: boolean = false;
  public editButtonRoute: string = '';
  public showMarkAsComplete: boolean = false;
  public disableApproveButton: boolean = true;
  public atleastOneTabCompleted: boolean = true;
  public markAsCompleteApiLoading: boolean = false;
  public refreshSnlComponent:boolean=true;
  public roles = ROLES;
  public selectedTab: string = 'OWNER';
  public departmentMapping : any= DEPARTMENTMAPPING;
  public currentUser: any = [];
  public selectedCoaType: string = '';
  public tabsData: any = [];
  public ownerAddedDocuments:any = [];
  public departmentDocuments:any = [];
  private departmentDataFetched = new Subject<boolean>();
  private userDataFetched = new Subject<boolean>();
  public autoSaveMessage: string = 'not-saved'; //not-saved, saved, saving
  public downloadingDocumentId: string = '';
  public openNotesPopup: boolean = false;
  public documentLegendStatus:string = 'no_document' //no_document, document_added
  public notesLegendStatus:string = 'no_notes' //no_notes, notes_added
  public notesTooltipVisibility:any = {};

  constructor(
    private ccdService: CcdService,
    public uploadService: FileUploadService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private toasterService: ToasterService,
    private el: ElementRef
  ) { }

  ngOnInit() {
    this.ccdNo = this.route.snapshot.queryParamMap.get('ccdNo');
    if (this.ccdNo) {
      this.updateActionStatus();
      this.fetchBackendUsersData();
      this.approveAndUploadAutoSaveMessage();
      this.subscribeToFetchApproveStatus();
      this.fetchCcdDepartments();
      this.fetchCcdData();
      this.fetchCcdDocuments();
      this.fetchCcdNotes();
      this.updateDocumentLegendStatus();
      this.updateNotesLegendStatus();
      //set header data
      const headerData = {
        headerTitle: `CCD#${this.ccdNo}`,
        backButtonNavigateTo: 'dashboard',
        breadCrumbList: [
          { id: '1', label: 'Dashboard', navigateTo:'dashboard',isClickable: true },
          { id: '3', label: `CCD#${this.ccdNo}`, isClickable: false }
        ]
      }
      this.navigationService.setHeaderData(headerData);
    }
    else{
      this.router.navigateByUrl('dashboard');
    }
  }

  fetchBackendUsersData() {
    //get current user data
    this.authService.getBackendUserData().subscribe((data: any) => {
      if (data) {
        this.userDataFetched.next(true);
        this.currentUser = data;
        if(this.currentUser?.role === ROLES['Owner']){
          this.editButtonRoute = `/dashboard/edit-ccd-details?ccdNo=${this.ccdNo}`;
        }
        else if(this.currentUser?.role === ROLES['Manager']){
          if(this.authService?.isDepartmentalDependency){
            this.editButtonRoute = `/dashboard/manage-ccd?ccdNo=${this.ccdNo}`;
          }
          else{
            this.editButtonRoute = `/dashboard/edit-ccd-details?ccdNo=${this.ccdNo}`;
          }
        }
      }
    });
  }

  subscribeToFetchApproveStatus(){
    this.ccdService.getCcdApproveStatus().subscribe((data: any) => {
      if(data?.from === 'approvePopup'){
        this.onSelectTab({department: 'MANAGEMENT'})
      }
    });
  }
  
  approveAndUploadAutoSaveMessage(){
    this.ccdService.getReviewScreenAutoSaveMessage().subscribe((message: string) => {
      this.autoSaveMessage = message;
    });
  }

  fetchCcdDepartments() {
    this.fetchDepartmentsLoading = true;
    this.ccdService.fetchCcdDepartments({ ccdNo: this.ccdNo }).subscribe({
      next: ({departments}: any) => {
        this.tabsData = departments.sort((a: any, b: any) => {
          return DEPARTMENTTABSORDER.indexOf(a.department) - DEPARTMENTTABSORDER.indexOf(b.department);
        });
        this.departmentDataFetched.next(true);
        this.fetchDepartmentsLoading = false;

        let isOnSelectTab= false;
        this.tabsData.forEach((tabData:any) => {
          if(this.currentUser?.department === tabData?.department){
            this.onSelectTab({department: tabData?.department});
            isOnSelectTab = true;
          }
        });
        if(!isOnSelectTab){
          this.onSelectTab({department: 'OWNER'});
        }

        this.checkIfAtleastOneTabCompleted();
        this.checkApproveButtonEnableDisable();
        
      },
      error: (err: any) => {
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        this.fetchDepartmentsLoading = false;
      }
    });
  }

  checkIfAtleastOneTabCompleted() {        
    const tempTabsData:any = JSON.parse(JSON.stringify(this.tabsData));
    this.atleastOneTabCompleted = tempTabsData.some((tabData: any) => {
      return tabData?.actionLegend?.name === 'completed';
    });
  }

  checkApproveButtonEnableDisable() {        
    const tempTabsData:any = JSON.parse(JSON.stringify(this.tabsData));
    const filteredTabsData = tempTabsData.filter((tabData: any) => tabData?.department !== 'MANAGEMENT');
    this.disableApproveButton = !filteredTabsData.every((tabData: any) => {
      return tabData?.actionLegend?.name === 'completed';
    });
  }

  fetchCcdData() {
    this.fetchDataLoading = true;
    this.ccdService.fetchCcdDataReview({ ccdNo: this.ccdNo }).subscribe({
      next: ({ccdRes, ownerAddedDocuments}: any) => {
        if(ccdRes?.[0].ccdStatus === 'draft'){
          this.router.navigate(['/dashboard']);
          return;
        }
        this.ccdRes = ccdRes?.[0];
        this.ownerAddedDocuments = ownerAddedDocuments;
        this.ccdService.setCcdApproveStatus({value: this.ccdRes?.isApproved, from: 'reviewScreen'});
        this.fetchDataLoading = false;
      },
      error: (err: any) => {
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        this.fetchDataLoading = false;
      }
    });
  }

  fetchCcdDocuments() {
    this.departmentDocuments = [];
    this.fetchCcdDocumentsLoading = true;
    this.ccdService.fetchCcdDocuments({ ccdNo: this.ccdNo, department: this.selectedTab }).subscribe({
      next: ({documents}: any) => {
        this.departmentDocuments = documents;
        this.fetchCcdDocumentsLoading = false;
      },
      error: (err: any) => {
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        this.fetchCcdDocumentsLoading = false;
      }
    });
  }

  fetchCcdNotes() {
    this.fetchCcdNotesLoading = true;
    this.ccdService.fetchCcdNotes({ ccdNo: this.ccdNo, department: 'OWNER' }).subscribe({
      next: ({notes}: any) => {
        this.ccdNotes = notes;
        this.fetchCcdNotesLoading = false;
        this.setTooltioOnNotes();
      },
      error: (err: any) => {
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        this.fetchCcdNotesLoading = false;
      }
    });
  }

  updateActionStatus() {
    forkJoin([
      this.departmentDataFetched.pipe(take(1)),
      this.userDataFetched.pipe(take(1))
    ]).subscribe({
      next: ([departmentObs, userData]: any) => {
        this.tabsData = this.tabsData.map((tab: any) => {
          if (tab.department === this.currentUser?.department && tab?.actionLegend?.name === 'no_action_taken') {
            const reqObj = {
              ccdNo: this.ccdNo,
              action: "ACTION",
              department: this.currentUser?.department,
            };
            this.ccdService.updateLegend(reqObj).subscribe({
              next: () => {
                tab.actionLegend.name = 'action_taken';
              },
              error: (err: any) => {
                this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
                console.error('Error updating legend:', err);
              }
            });
          }
          return tab;
        });
      },
      error: (error) => {
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        console.error('Error fetching data:', error);
      }
    });

  }

  onSelectTab(tab: any) {
    this.selectedTab = tab?.department;
    this.setDocumentLegendStatus();
    this.setNotesLegendStatus();
    this.presetMarkAsCompleteToggle();
    this.fetchCcdDocuments();
    this.refreshSnlComponent=false;
    setTimeout(() => {
      this.refreshSnlComponent=true;
    }, 1);

    if(tab?.department === 'OWNER'){
      this.setTooltioOnNotes();
    }
  }

  setTooltioOnNotes(){
    setTimeout(() => {
      const notesIdArray = ['legalNote', 'accountingNote', 'operationsManagerNote', 'tankerControllerNote', 'demurrageNote', 'groupRiskNote', 'managementNote'];
      notesIdArray.forEach((id: any) => {
        const element = this.el.nativeElement.querySelector(`#${id}`);
        if(element){
          const width = element.offsetWidth;
          if (width > 400) {
            this.notesTooltipVisibility[id] = true;
          }
          else{
            this.notesTooltipVisibility[id] = false;
          }
        }
      });
    }, 1);
  }

  setDocumentLegendStatus(){
    this.documentLegendStatus = this.tabsData.find((tabData: any) => tabData?.department === this.selectedTab)?.documentLegend?.name;
    this.ccdService.setDepartmentSpecificDocumentLegend({department:this.selectedTab, documentLegendStatus:this.documentLegendStatus})
  }
  setNotesLegendStatus(){
    this.notesLegendStatus = this.tabsData.find((tabData: any) => tabData?.department === this.selectedTab)?.notesLegend?.name;
    this.ccdService.setDepartmentSpecificNotesLegend({department:this.selectedTab, notesLegendStatus:this.notesLegendStatus})
  }

  updateDocumentLegendStatus(){
    this.ccdService.getUpdatedDocumentLegend().subscribe((event:any)=>{
      this.tabsData = this.tabsData.map((tabData: any) => {
        if(tabData?.department === event?.department){
          tabData.documentLegend = {name: event?.documentStatus};
        }
        return tabData;
      });
    })
  }
  updateNotesLegendStatus(){
    this.ccdService.getUpdatedNotesLegend().subscribe((event:any)=>{
      this.tabsData = this.tabsData.map((tabData: any) => {
        if(tabData?.department === event?.department){
          tabData.notesLegend = 'notes_added';
        }
        return tabData;
      });
    })
  }

  presetMarkAsCompleteToggle() {
    this.tabsData?.forEach((tabData:any) => {
      if(tabData?.department === this.selectedTab){
        this.showMarkAsComplete = tabData?.actionLegend?.name === 'completed';
      }
    });
  }

  markAsComplete(event:any) {
    const reqObj = {
      ccdNo: this.ccdNo,
      department: this.selectedTab,
      value: event.isSwitchActive ? 'Yes' : 'No'
    };
    this.markAsCompleteApiLoading = true;
    this.autoSaveMessage = 'saving';
    this.showMarkAsComplete = event.isSwitchActive;
    this.ccdService.markAsComplete(reqObj).subscribe({
      next: ({ccdRes}:any) => {
        
        //update legends
        this.tabsData = this.tabsData.map((tab: any) => {
          if (tab.department === 'MANAGEMENT' && !event.isSwitchActive) {
            tab.actionLegend.name = 'action_taken';
          }
          if (tab.department === ccdRes?.department) {
            tab.actionLegend.name = ccdRes?.legend?.name;
          }
          return tab;
        });

        //change approve status and button enable/disable
        if(!event.isSwitchActive){
          this.ccdService.setCcdApproveStatus({value: false, from: 'reviewScreen'});
        }
        this.checkIfAtleastOneTabCompleted();
        this.checkApproveButtonEnableDisable();
        
        this.markAsCompleteApiLoading = false;
        this.autoSaveMessage = 'saved';
        
      },
      error: (error: any) => {
        if (error?.status === 423) {
          this.revokeMarkAsComplete(reqObj, event);
          this.toasterService.openToastCcd(Toasts.Types.Warning, error?.error?.reason, Toasts.Actions.MarkComplete.WarningTitle);
          this.autoSaveMessage = 'saved';
        }
        else if (error?.status === 424) {
          this.revokeMarkAsComplete(reqObj, event);
          this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.reason);
        }
        else{
          this.revokeMarkAsComplete(reqObj, event);
          this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        }

        this.markAsCompleteApiLoading = false;
      }
    });
  }
  revokeMarkAsComplete(reqObj:any, event:any) {
    if(reqObj?.department === this.selectedTab)
    {
      this.showMarkAsComplete = !event.isSwitchActive;
      this.refreshSnlComponent=false;
      setTimeout(() => {
        this.refreshSnlComponent=true;
      }, 1);
    }
    this.autoSaveMessage = 'not-saved';
  }
  
  accessToManagerAndCcdOwner(): boolean {
    return this.currentUser?.role === this.roles['Manager'] ||
      (this.currentUser?.role === this.roles['Owner'] && this.ccdRes?.createdBy === this.currentUser?.userId);
  }
  
  accessToAssignedManager(): boolean {
    return this.currentUser?.role === this.roles['Manager'] && this.ccdRes?.managerId === this.currentUser?.userId;
  }
  
  accessOfUploadDocument(): boolean {
    return this.ccdRes?.ccdStatus === 'active' && (this.currentUser?.role === ROLES['Manager'] || (this.currentUser?.role === ROLES['DepartmentUser'] && this.currentUser?.departmentAccess === 'EDIT' && this.currentUser?.department === this.selectedTab));
  }
  
  accessOfNotesEditor(): boolean {
    return this.ccdRes?.ccdStatus === 'active' && (this.currentUser?.role === ROLES['Manager'] || (this.currentUser?.role === ROLES['DepartmentUser'] && this.currentUser?.departmentAccess === 'EDIT' && this.currentUser?.department === this.selectedTab) || (this.currentUser?.role === ROLES['Owner'] && this.currentUser?.userId === this.ccdRes?.createdBy));
  }
  
  accessOfActionPanel(): boolean {
    return this.ccdRes?.ccdStatus === 'active' && this.accessToManagerAndCcdOwner();
  }

  accessOfEditButton(): boolean {
    return !this.atleastOneTabCompleted;
  }

  editButtonVisibility(): boolean {
    return this.accessToManagerAndCcdOwner() && (this.selectedTab === 'OWNER' || this.selectedTab === 'MANAGEMENT') && this.ccdRes?.ccdStatus === 'active';
  }

  accessOfApproveButton(): boolean {
    return this.selectedTab === 'MANAGEMENT' && this.ccdRes?.ccdStatus === 'active' && this.accessToAssignedManager();
  }

  isManagementTabOpen():boolean{
    return this.selectedTab === 'MANAGEMENT';
  }

  disableMarkAsComplete() {
    return (this.selectedTab === 'MANAGEMENT' && (!this.ccdService.ccdApprovedStatus?.value || this.disableApproveButton || (this.currentUser?.role === this.roles['Owner'] && this.showMarkAsComplete))) || this.markAsCompleteApiLoading;
  }

  isEmptyObject(obj: any): boolean {
    return obj && Object.keys(obj).length === 0;
  }

  downloadFile(docId: string) {
    this.downloadingDocumentId = docId;
    this.uploadService.downloadFile(docId).subscribe({
      next: (response: HttpResponse<Blob>) => {
        const blob = response.body as Blob;
  
        // Extract Content-Disposition header
        const contentDisposition = response.headers.get('Content-Disposition') || '';
  
        // Extract the file name
        const fileName = this.getFileNameFromDisposition(contentDisposition) || 'downloaded-file.pdf';
  
        // Create a link and trigger download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = this.uploadService.extractFileName(fileName);
        link.click();
  
        // Cleanup
        window.URL.revokeObjectURL(url);
        this.downloadingDocumentId = '';
      },
      error: (error) => {
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.DownloadDocument.Error);
        console.error('Error downloading file:', error);
        this.downloadingDocumentId = '';
      },
    });
  }

  getFileNameFromDisposition(disposition: string): string | null {
    const matches = /filename="?([^"]+)"?/.exec(disposition);
    return matches?.[1] || null;
  }

  openNotesPopupBox(){
    this.openNotesPopup = true;
  }
  closeNotesPopup() {
    this.openNotesPopup = false;
    this.refreshSnlComponent=false;
    setTimeout(() => {
      this.refreshSnlComponent=true;
    }, 1);
  }
}

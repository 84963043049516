import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonModule, DropdownModule, InputModule, ModalModule, SwitchModule } from 'snl-complib';
import { CcdService } from 'src/app/services/ccd.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { customizedMessages, Toasts } from 'src/app/constant/toast.messages';
import { CommonModule } from '@angular/common';
import { DIVISIONS } from 'src/app/constant/constant';

@Component({
  selector: 'app-add-user-popup',
  standalone: true,
  imports: [ModalModule, SvgIconComponent, InputModule, DropdownModule, SwitchModule, ButtonModule, ReactiveFormsModule, CommonModule],
  templateUrl: './add-user-popup.component.html',
  styleUrl: './add-user-popup.component.scss'
})
export class AddUserPopupComponent {
  public modalSettings: any;
  public divisions: any = DIVISIONS;
  public divisionOptions: any = [];
  public userForm!: FormGroup;
  public userSearchResult: any = [];
  public showSearchResult:boolean = false;
  public refreshManagementOwnerSwitch:boolean=true;
  public refreshAdminSwitch:boolean=true;
  public refreshDepartmentViewonlySwitch:boolean=true;
  public submitLoading:boolean = false;
  public managementSwitchState: boolean = false;
  public ownerSwitchState: boolean = false;
  public adminSwitchState: boolean = false;
  public departmentSwitchState: boolean = false;
  public viewOnlySwitchState: boolean = false;
  public disableManagementSwitch: boolean = false;
  public disableOwnerSwitch: boolean = false;
  public disableAdminSwitch: boolean = false;
  public disableDepartmentSwitch: boolean = false;
  public disableViewOnlySwitch: boolean = false;
  public isEditMode: boolean = false;
  public divisionObj: any = [];
  @Input() openAddUserPopup: boolean = false;
  @Input() userDataForPopulation: any = {};
  @Output() onButtonClick: EventEmitter<any | null> = new EventEmitter();

  constructor(
    private ccdService: CcdService,
    private toasterService: ToasterService
  ) { }

  ngOnInit(){
    this.modalSettings = { showCloseIcon: true, backdropClick: false };
    this.fetchDivisionOptions();
    this.createUserForm();
    this.subscribeNameSearch();
    if(Object.keys(this.userDataForPopulation).length > 0)
    {
      this.isEditMode = true;
      this.populateUserData();
    }
  }
  
  fetchDivisionOptions() {
    this.divisionOptions = Object.keys(this.divisions).map(key => ({
      id: key,
      name: this.divisions[key]
    }));
  }

  createUserForm() {
    this.userForm = new FormGroup({
      nameSearch: new FormControl(''),
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', Validators.required),
      userId: new FormControl('', Validators.required),
      designation: new FormControl(''),
      division: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      departmentAccess: new FormControl(''),
      isAdmin: new FormControl(false, Validators.required),
    });
  }

  subscribeNameSearch() {
    const escapeRegExp = (text: string) => {
      return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');  // Escape all special characters
    };
    this.userForm.get('nameSearch')?.valueChanges.subscribe(value => {
      
      this.userForm.patchValue({
        name: '',
        email: '',
        userId: '',
        designation: ''
      });
      if(value?.length > 1){
        this.showSearchResult = true;
        const searchValue = value.toLowerCase();
        this.userSearchResult = this.ccdService.usersDataFromAd
        .filter((user: any) => user.displayName.toLowerCase().includes(searchValue)) // Filter the results
        .sort((a: any, b: any) => {
          const indexA = a.displayName.toLowerCase().indexOf(searchValue);
          const indexB = b.displayName.toLowerCase().indexOf(searchValue);
    
          // Sort based on the position of the match in the string
          if (indexA === indexB) {
            return a.displayName.localeCompare(b.displayName); // Secondary alphabetical sort
          }
          return indexA - indexB;
        })
        .map((user: any) => {
          const regex = new RegExp(`(${escapeRegExp(searchValue)})`, 'gi');  // Regex to match all occurrences
          const highlightedDisplayName = user.displayName.replace(regex, '<strong>$1</strong>');
          return {
            ...user,
            highlightedDisplayName
          };
        }) || [];
      }
      else{
        this.showSearchResult = false;
      }
    });
  }

  selectUser(user:any){
      this.userForm.patchValue({
        nameSearch: user?.displayName,
        name: user?.displayName,
        email: user?.userPrincipalName,
        userId: user?.id,
        designation: user?.jobTitle
      });
      this.userSearchResult = [];
      this.showSearchResult = false;
  }

  changeDivision(event: any) {
    this.userForm.patchValue({
      division: event?.selectedRecords?.id,
      role: '',
      departmentAccess: '',
      isAdmin: false
    });
    this.managementSwitchState = false;
    this.ownerSwitchState = false;
    this.adminSwitchState = false;
    this.departmentSwitchState = false;
    this.viewOnlySwitchState = false;
    this.refreshManagementOwnerSwitchFunction();
    this.refreshAdminSwitchFunction();
    this.refreshDepartmentViewonlySwitchFunction();
    
    const departmentAccess = this.userForm.get('departmentAccess');
    if(event?.selectedRecords?.id === 'COMMERCIAL'){
      if (departmentAccess) {
        departmentAccess.clearValidators();
        departmentAccess?.updateValueAndValidity();
      }
      this.disableManagementSwitch = false;
      this.disableOwnerSwitch = false;
      this.disableAdminSwitch = false;
      this.disableDepartmentSwitch = true;
      this.disableViewOnlySwitch = true;
    }
    else if(['LEGAL','ACCOUNTING','OPERATIONS_MANAGER','TANKER','DEMURRAGE','GROUP_RISK'].includes(event?.selectedRecords?.id)){
      if (departmentAccess) {
        departmentAccess.setValidators(Validators.required);
        departmentAccess?.updateValueAndValidity();
      }
      this.disableManagementSwitch = true;
      this.disableOwnerSwitch = true;
      this.disableAdminSwitch = true;
      this.disableDepartmentSwitch = false;
      this.disableViewOnlySwitch = false;
    }
    else if(event?.selectedRecords?.id === 'OTHER'){
      if (departmentAccess) {
        departmentAccess.clearValidators();
        departmentAccess?.updateValueAndValidity();
      }
      this.disableManagementSwitch = true;
      this.disableOwnerSwitch = true;
      this.disableAdminSwitch = true;
      this.disableDepartmentSwitch = true;
      this.disableViewOnlySwitch = true;

      this.userForm.patchValue({ role: '4' });
      this.viewOnlySwitchState = true;
    }
  }
  resetDivision() {
    this.userForm.patchValue({ division: '' });
  }

  closeModalPopUp() {
    this.onButtonClick.emit({}) 
  }

  managementSwitchClick(event:any){
    this.managementSwitchState = event?.isSwitchActive;
    if(event?.isSwitchActive){
      this.userForm.patchValue({ role: '1' });
      this.ownerSwitchState = false;
    }
    else{
      this.userForm.patchValue({ role: '2' });
      this.ownerSwitchState = true;
    }
    this.refreshManagementOwnerSwitchFunction();
  }
  ownerSwitchClick(event:any){
    this.ownerSwitchState = event?.isSwitchActive;
    if(event?.isSwitchActive){
      this.userForm.patchValue({ role: '2' });
      this.managementSwitchState = false;
    }
    else{
      this.userForm.patchValue({ role: '1' });
      this.managementSwitchState = true;
    }
    this.refreshManagementOwnerSwitchFunction();
  }
  adminSwitchClick(event:any){
    this.adminSwitchState = event?.isSwitchActive;
    if(event?.isSwitchActive){
      this.userForm.patchValue({ isAdmin: true });
    }
    else{
      this.userForm.patchValue({ isAdmin: false });
    }
  }
  departmentSwitchClick(event:any){
    this.departmentSwitchState = event?.isSwitchActive;
    if(event?.isSwitchActive){
      this.userForm.patchValue({
        role: '3', 
        departmentAccess: 'EDIT'
      });
      this.viewOnlySwitchState = false;
    }
    else{
      this.userForm.patchValue({
        role: '3',
        departmentAccess: 'VIEW'
      });
      this.viewOnlySwitchState = true;
    }
    this.refreshDepartmentViewonlySwitchFunction();
  }
  viewOnlySwitchClick(event:any){
    //this code is for department division
    this.viewOnlySwitchState = event?.isSwitchActive;
    if(event?.isSwitchActive){
      this.userForm.patchValue({
        role: '3', 
        departmentAccess: 'VIEW'
      });
      this.departmentSwitchState = false;
    }
    else{
      this.userForm.patchValue({
        role: '3',
        departmentAccess: 'EDIT'
      });
      this.departmentSwitchState = true;
    }
    this.refreshDepartmentViewonlySwitchFunction();
  }

  refreshManagementOwnerSwitchFunction(){
    this.refreshManagementOwnerSwitch=false;
    setTimeout(() => {
      this.refreshManagementOwnerSwitch=true;
    }, 1);
  }
  refreshAdminSwitchFunction(){
    this.refreshAdminSwitch=false;
    setTimeout(() => {
      this.refreshAdminSwitch=true;
    }, 1);
  }
  refreshDepartmentViewonlySwitchFunction(){
    this.refreshDepartmentViewonlySwitch=false;
    setTimeout(() => {
      this.refreshDepartmentViewonlySwitch=true;
    }, 1);
  }
  
  dataWithoutNameSearchField() {
    const { nameSearch, ...newObj } = this.userForm.value;
    return newObj;
  }
  addUser() {
    if(this.userForm.invalid) {
      return;
    }
    if(this.isEditMode){
      this.updateUser();
      return;
    }
    this.submitLoading = true;
    const reqData = this.dataWithoutNameSearchField();
    this.ccdService.createUser({ userData: reqData }).subscribe({
      next: () => {
        this.submitLoading = false;
        this.onButtonClick.emit({
          isUserAdded: true
        });
        this.toasterService.openToastCcd(Toasts.Types.Success, customizedMessages('AddUser', '', this.userForm?.get('name')?.value), Toasts.Actions.AddUser.Title);
      },
      error: (error) => {
        this.submitLoading = false;
        if (error?.status === 424) {
          this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.reason);
        } else {
          this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        }
        console.error('Error creating users data:', error);
      }
    });
  }

  populateUserData() {
    if (this.userDataForPopulation?.division) {
      this.divisionObj = [{ "id": this.userDataForPopulation?.division , "name": this.divisions[this.userDataForPopulation?.division] }];
      this.changeDivision({ selectedRecords: { "id": this.userDataForPopulation?.division , "name": this.divisions[this.userDataForPopulation?.division] } });

      if(this.userDataForPopulation?.role === '1'){
        this.managementSwitchState = true;
      }
      else if(this.userDataForPopulation?.role === '2'){
        this.ownerSwitchState = true;
      }
      else if(this.userDataForPopulation?.role === '3'){
        if(this.userDataForPopulation?.departmentAccess === 'VIEW'){
          this.departmentSwitchState = false;
          this.viewOnlySwitchState = true;
        }
        else{
          this.departmentSwitchState = true;
          this.viewOnlySwitchState = false;
        }
      }
      else if(this.userDataForPopulation?.role === '4'){
        this.viewOnlySwitchState = true;
      }

      if(this.userDataForPopulation?.isAdmin){
        this.adminSwitchState = true;
      }
    }
    this.userForm.patchValue({
      nameSearch: this.userDataForPopulation?.name,
      name: this.userDataForPopulation?.name,
      email: this.userDataForPopulation?.email,
      userId: this.userDataForPopulation?.userId,
      designation: this.userDataForPopulation?.designation,
      division: this.userDataForPopulation?.division,
      role: this.userDataForPopulation?.role,
      isAdmin: this.userDataForPopulation?.isAdmin,
      departmentAccess: this.userDataForPopulation?.departmentAccess
    });
  }

  
  dataWithoutUserInfoFields() {
    const { nameSearch, name, email, designation, ...newObj } = this.userForm.value;
    return newObj;
  }
  updateUser(){
    this.submitLoading = true;
    const reqData = this.dataWithoutUserInfoFields();
    this.ccdService.updateUser({ type: 'UPDATE', userData: reqData }).subscribe({
      next: () => {
        this.submitLoading = false;
        this.onButtonClick.emit({
          isUserAdded: true
        });
        this.toasterService.openToastCcd(Toasts.Types.Information, customizedMessages('UpdateUser', '', this.userForm?.get('name')?.value), Toasts.Actions.UpdateUser.Title);
      },
      error: (error) => {
        this.submitLoading = false;
        if (error?.status === 424) {
          this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.reason);
        } else {
          this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        }
        console.error('Error updating users data:', error);
      }
    });
  }
  
}

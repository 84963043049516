<snl-modal class="CommnModalChanges CoomonModel400" (modalClose)="closeModalPopUp()" [settings]="modalSettings" [type]="'small'" [open]="open"> 
    <div slot="heading">Cancel editing CCD</div> 
    <div slot="modalContent">
       <p class="body-b1">
        Are you sure you want cancel editing this CCD? Changes you made will not be saved and will be discarded.
       </p>
    </div> 
    <div slot="modalFooter">
      <snl-button class="BtnSkeleton" size="large" (click)="cancelEditCcdAndCloseModalPopUp()">Yes</snl-button>
      <snl-button size="large" type="secondary" (click)="closeModalPopUp()">No</snl-button>
    </div>
</snl-modal>

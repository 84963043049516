<section class="DetailsWrapMainStep2">
    <!-- Left Card CCD Preview Details  -->
    <div class="InfoCardLftHolder">
        <app-preview-ccd-details [ownerAddedDocuments]="ownerAddedDocuments" [ccdRes]="ccdRes" [editVisibility]="true" [showEditButton]="true" [editButtonRoute]="editButtonRoute" [fetchDataLoading]="fetchDataLoading"></app-preview-ccd-details>
    </div>
    
    <!-- Right Card CCD Departments’ notes  -->
    <div class="InfoCardLft InfoCardRgt">
        <h2 class="sub-heading-s1">Departments’ notes</h2>
        <p class="body-b SubHeadingBtm">Enter notes for all the departments</p>
        <form [formGroup]="ccdForm">
            <ul>
                @if (fetchDataLoading) {
                <li class="Stolt-Skeleton" *ngFor="let number of [0,1,2,3,4,5,6]"></li>
                }
                @else if(departmentFields && departmentFields.length > 0) {
                <li *ngFor="let field of departmentFields"[ngClass]="{'ErrorSpaceBottom': showError(field?.fieldName), 'fullAreaDisable': field?.switchState}">
                    <div class="FieldHeader">
                        <span class="body-b2 FieldTitle">{{field?.header}}</span>
                        <div class="FieldSwitch" *ngIf="isManager() && authService?.isDepartmentalDependency">
                            <span class="body-b2">Applicable</span>
                            <snl-switch
                            [activeText]="'YES'"
                            [inActiveText]="'NO'"
                            [toggleState]="field?.realTimeSwitchState"
                            (toggle)="departmentSwitchClick($event, field?.fieldName)"
                            [disabled]="field?.switchState"
                            ></snl-switch>
                        </div>
                    </div>
                    <snl-input [class]="!field?.realTimeSwitchState ? 'hideDepartmentField' : ''" [label]="'Notes'" [formControlName]="field?.fieldName" [errorMessage]="showError(field?.fieldName)"></snl-input>
                </li>
                }
                @else {
                    <span class="body-b1">NA</span>
                }
            </ul>
        </form>   
    </div>
</section>
<!-- Sticky Footer Bottom Action Panel -->
<footer class="FixedFooterBar">
    <snl-action-panel>
        <div class="gap16 df-c" left-side>
            <snl-button class="BtnSkeleton" [ngClass]="{'Stolt-Skeleton': submitLoading}" [size]="'large'" (buttonClick)="submitPopup()" [disabled]="!areAllRequiredFieldsFilled()">Submit</snl-button>
            <snl-button class="BtnSkeleton" [ngClass]="{'Stolt-Skeleton': saveExitLoading}" [size]="'large'" [type]="'secondary'" (buttonClick)="cancelManageCcd()">Cancel</snl-button>
        </div>
    </snl-action-panel>
</footer>
@if(openActivateDepartmentPopup) {
    <app-activate-department [open]="openActivateDepartmentPopup" [activateDepartmentSubmitLoading]="submitLoading" (onButtonClick)="onSubmit($event)"></app-activate-department>
}
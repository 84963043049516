<snl-modal class="CommnModalChanges CoomonModel400" (modalClose)="closeModalPopUp()" [settings]="modalSettings" [type]="'small'" [open]="open"> 
    <div slot="heading">Activate Departments</div> 
    <div slot="modalContent">
       <p class="body-b1">
        Are you sure you want to activate these departments?
       </p>
    </div> 
    <div slot="modalFooter">
      <snl-button class="BtnSkeleton" size="large" [ngClass]="{'Stolt-Skeleton':activateDepartmentSubmitLoading}" (click)="activateAndCloseModalPopUp()">Yes</snl-button>
      <snl-button size="large" type="secondary" (click)="closeModalPopUp()">Cancel</snl-button>
    </div>
</snl-modal>

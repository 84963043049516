import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { roleGuard } from './guards/role.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ManageUsersComponent } from './pages/manage-users/manage-users.component';
import { CreateCcdStep1Component } from './pages/create-ccd-step1/create-ccd-step1.component';
import { CreateCcdStep2Component } from './pages/create-ccd-step2/create-ccd-step2.component';
import { ReviewAndApproveComponent } from './pages/review-and-approve/review-and-approve.component';
import { NavComponent } from './components/nav/nav.component';
import { EditCcdDetailsComponent } from './pages/edit-ccd-details/edit-ccd-details.component';
import { adminAccessGuard } from './guards/admin-access.guard';
import { ManageCcdComponent } from './pages/manage-ccd/manage-ccd.component';
import { managerGuard } from './guards/manager.guard';
import { validUserGuard } from './guards/valid-user.guard';

export const appRoutes: Routes = [
  {
    path: '',
    component: NavComponent,
    canActivate: [MsalGuard],
    children: [
      { path: '', component: DashboardComponent, canActivate: [validUserGuard] },
      { 
        path: 'dashboard',
        children: [
          { path: '', component: DashboardComponent, canActivate: [validUserGuard] },
          { path: 'create-ccd-step1', component: CreateCcdStep1Component, canActivate: [roleGuard] },
          { path: 'create-ccd-step2', component: CreateCcdStep2Component, canActivate: [roleGuard] },
          { path: 'review-ccd', component: ReviewAndApproveComponent, canActivate: [validUserGuard] },
          { path: 'manage-ccd', component: ManageCcdComponent, canActivate: [managerGuard] },
          { path: 'edit-ccd-details', component: EditCcdDetailsComponent, canActivate: [roleGuard] },
        ]
      },
      {
        path: 'manage-users', component: ManageUsersComponent,
        canActivate: [adminAccessGuard]
      },
    ]
  }
];
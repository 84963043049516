import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { ActionPanelModule, AlertModule, ButtonModule, DropdownModule, IconModule, SwitchModule, TableModule, TooltipModule } from 'snl-complib';
import { TabsComponent } from 'src/app/components/tabs/tabs.component';
import { Tabs } from 'src/app/interfaces/tabs-interface';
import { ACTIVE, UPCOMING, COMPLETED, CANCELLED, DRAFT, DEPARTMENTS, DEPARTMENTORDER } from 'src/app/constant/table-headings';
import { TYPEOFCOA, ROLES } from 'src/app/constant/constant';
import { TABS } from 'src/app/constant/tabs-constant';
import { CcdService } from 'src/app/services/ccd.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { FiltersService } from 'src/app/services/filters.service';
import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonModule } from '@angular/common';
import { finalize, Subject, switchMap } from 'rxjs';
import { LegendPanelComponent } from 'src/app/components/legend-panel/legend-panel.component';
import { NoDataFoundScreenComponent } from 'src/app/components/no-data-found-screen/no-data-found-screen.component';
import { CancelCcdPopupComponent } from 'src/app/components/cancel-ccd-popup/cancel-ccd-popup.component';
import { DeleteNewCcdCreationComponent } from 'src/app/components/delete-new-ccd-creation/delete-new-ccd-creation.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { customizedMessages, Toasts } from 'src/app/constant/toast.messages';


@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [DeleteNewCcdCreationComponent, CommonModule, TabsComponent, LegendPanelComponent, NoDataFoundScreenComponent, CancelCcdPopupComponent, SvgIconComponent, ButtonModule, IconModule, DropdownModule, TableModule, ActionPanelModule, SwitchModule, TooltipModule,AlertModule],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
  public tableHeadings = ACTIVE;
  public departmentHeadings = DEPARTMENTS;
  public ccdTabs: Tabs[] = Object.values(TABS);
  public typeOfCOA: any = TYPEOFCOA;
  public selectedTab: string = 'active';
  public loading: boolean = false;
  public buttonLabel: string = 'Start a new CCD';
  public ccdList: any = [];
  public ccdCount: any = [];
  public roles = ROLES;
  public currentUser: any = [];
  public openCancelPopup: boolean = false;
  public openDeleteDraftPopup: boolean = false;
  public cancelCcdNo: string = '';
  public deleteCcdNo: string = '';
  public customerOptions: any;
  public lobOptions: any;
  public ownerOptions: any;
  public typeOfCoaOptions: any;
  public selectedFilters: any = { 'customer': [], 'lob': [], 'owner': [], 'coaType': [] };
  public filteredCcdData: any = [];
  public publishLoading: boolean = false;
  public publishLoadingCcdNo: string = '';
  public initiateLoading: boolean = false;
  public initiateLoadingCcdNo: string = '';
  public reactivateLoading: boolean = false;
  public reactivateLoadingCcdNo: string = '';
  public cancelPopupSubmitLoading: boolean = false;
  public deletePopupDeleteLoading: boolean = false; 
  private tabSelection$ = new Subject<string>();
  public alertVisibility: boolean = false;
  public alert: any = {'alertMessage': "", 'alertType': "Single", 'linkText': "", 'linkUrl': "#"};
  constructor(
    private ccdService: CcdService,
    private toasterService: ToasterService,
    private authService: AuthService,
    private filtersService: FiltersService,
    private storageService: StorageService,
    private navigationService: NavigationService,
    private readonly router: Router
  ) { }

  ngOnInit() {
    this.fetchCcdList();
    //set header data
    const headerData = {
      headerTitle: 'Dashboard',
      breadCrumbList: []
    }
    this.navigationService.setHeaderData(headerData);
    this.selectedTab = this.storageService.getDataFromStorage('selectedTab') || 'active';
    const tabs: any = TABS;
    this.onTabSelect(tabs[this.selectedTab], 'ngOnInit');
    this.authService.getBackendUserData().subscribe((data: any) => {
      if (data) {
        this.currentUser = data;
        if([ROLES['Manager'], ROLES['Owner']].includes(this.currentUser?.role)){
          this.fetchUpcomingNotification();
        }
      }
    });
  }

  onTabSelect(event: any, clickedFrom: string = '') {
    if (clickedFrom != 'ngOnInit') {
      this.selectedTab = event.value;
      this.storageService.putDataInStorage('selectedTab', this.selectedTab);
      /// this.filters = { 'customer': [], 'lob': [], 'owner': [], 'coaType': []};
    }
    this.ccdTabsAllocation();
    this.fetchCcdCount();
    this.tabSelection$.next(this.selectedTab);
  }

  ccdTabsAllocation() {
    switch (this.selectedTab) {
      case 'active':
        this.tableHeadings = ACTIVE;
        break;
      case 'upcoming':
        this.tableHeadings = UPCOMING;
        break;
      case 'completed':
        this.tableHeadings = COMPLETED;
        break;
      case 'cancelled':
        this.tableHeadings = CANCELLED;
        break;
      case 'draft':
        this.tableHeadings = DRAFT;
        break;
      default:
        this.tableHeadings = ACTIVE;
    }
  }

  fetchUpcomingNotification() {
    this.ccdService.fetchUpcomingAlert()
      .subscribe({
        next: ({Message, Visibility, dueCCDsWithin2weeks }: any) => {
          this.alertVisibility = Visibility;
          this.alert.alertMessage = Message;
          this.alert.alertType = dueCCDsWithin2weeks?.length > 1 ? "Multiple" : "Single";
          this.alert.linkText = dueCCDsWithin2weeks?.length > 1 ? "Upcoming CCDs" : `View CCD#${dueCCDsWithin2weeks?.[0]?.ccdNo}`;
          this.alert.linkurl = "#";
        },
        error: (error) => {
          this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
          console.error('Error fetching CCD count:', error);
        }
      });
  }

  fetchCcdCount() {
    this.ccdService.fetchCCDCount()
      .subscribe({
        next: ({ ccdCount }: any) => {
          this.ccdCount = ccdCount;
          this.ccdTabCountUpdation();
        },
        error: (error) => {
          this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
          console.error('Error fetching CCD count:', error);
        }
      });
  }

  fetchCcdList() {
    this.tabSelection$
    .pipe(
      switchMap((selectedTab) => {
        this.loading = true;
        const reqData = { status: selectedTab };
        return this.ccdService.fetchCCDList(reqData).pipe(
          finalize(() => (this.loading = false))
        );
      })
    )
    .subscribe({
      next: ({ ccdData }: any) => {
        if (ccdData?.length > 0) {
          ccdData.map((ccd: any) => {
            let sortedDepartments: any;
            if (['active', 'completed', 'cancelled'].includes(this.selectedTab)) {
              ccd['updatedDepartmentList'] = [];
              DEPARTMENTORDER.forEach((dept) => {
                const existingDepartment = ccd?.departmentList.find((d: any) => d.department === dept);
                if (!existingDepartment) {
                  ccd?.['updatedDepartmentList'].push({
                    department: dept,
                    legend: [],
                  });
                } else {
                  ccd?.['updatedDepartmentList'].push(existingDepartment);
                }
              });
              sortedDepartments = ccd?.departmentList.sort((a: any, b: any) =>
                DEPARTMENTORDER.indexOf(a.department) - DEPARTMENTORDER.indexOf(b.department)
              );
            }
            return sortedDepartments;
          });
        }
        this.ccdList = ccdData;
        this.setFilterOptionsAndFilterCcdData();
        this.checkAndsaveLogInLogs();
      },
      error: (error) => {
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        console.error('Error fetching CCD list:', error);
      },
    });
  }

  checkAndsaveLogInLogs(){
    const currentDate = new Date().toISOString().split('T')[0];
			if (this.storageService.getDataFromStorage('loggedIn') === 'false' ||
				!this.storageService.getDataFromStorage('loggedIn') ||
				this.storageService.getDataFromStorage('lastLoginDate') !== currentDate) {
				this.updateLogs();
			}
  }

  updateLogs() {
		this.ccdService.updateAuditLogs({ type: 'login' }).subscribe((response) => {
			this.storageService.putDataInStorage('loggedIn', 'true');
			const currentDate = new Date().toISOString().split('T')[0];
			this.storageService.putDataInStorage('lastLoginDate', currentDate);
		});
	}

  setFilterOptionsAndFilterCcdData() {
    this.setFilterOptions(this.ccdList, '');
    this.selectedFilters = this.storageService.getDataFromStorage(this.selectedTab + 'TabFilters') ? this.storageService.getDataFromStorage(this.selectedTab + 'TabFilters') : { 'customer': [], 'lob': [], 'owner': [], 'coaType': [] };
    this.filterCcdData();
  }

  setFilterOptions(dataList: any, key: any) {
    if (key == 'customer') {
      this.lobOptions = this.getDropdownoptions('lob', dataList);
      this.ownerOptions = this.getDropdownoptions('owner', dataList);
      this.typeOfCoaOptions = this.getDropdownoptions('coaType', dataList);
    } else if (key == 'lob') {
      this.customerOptions = this.getDropdownoptions('customer', dataList);
      this.ownerOptions = this.getDropdownoptions('owner', dataList);
      this.typeOfCoaOptions = this.getDropdownoptions('coaType', dataList);
    } else if (key == 'owner') {
      this.customerOptions = this.getDropdownoptions('customer', dataList);
      this.lobOptions = this.getDropdownoptions('lob', dataList);
      this.typeOfCoaOptions = this.getDropdownoptions('coaType', dataList);
    } else if (key == 'coaType') {
      this.customerOptions = this.getDropdownoptions('customer', dataList);
      this.lobOptions = this.getDropdownoptions('lob', dataList);
      this.ownerOptions = this.getDropdownoptions('owner', dataList);
    } else {
      this.customerOptions = this.getDropdownoptions('customer', dataList);
      this.lobOptions = this.getDropdownoptions('lob', dataList);
      this.ownerOptions = this.getDropdownoptions('owner', dataList);
      this.typeOfCoaOptions = this.getDropdownoptions('coaType', dataList);
    }
  }

  getDropdownoptions(key: any, data: any) {
    let uniqueValues = [...new Set(data.map((item: any) => item[key]))];
    if (key == 'lob') {
      uniqueValues = [...new Set(uniqueValues.flat())];
    }
    const objectsArray = uniqueValues
      .filter((name: any) => name)
      .map((name: any, index) => (key == 'coaType' ? {
        id: this.typeOfCOA[name],
        name: this.typeOfCOA[name]
      } : {
        id: name,
        name
      }));
    return objectsArray
  }

  applySelectedValues(event: any, key: any) {
    this.selectedFilters[key] = event.selectedRecords.map((value: any) => {
      return value;
    });
    this.filterCcdData()
    this.setFilterOptions(this.filteredCcdData, key);
  }

  filterCcdData() {
    this.filteredCcdData = this.filtersService.filterItems(
      this.ccdList,
      this.selectedFilters
    );
    this.updateFiltersInStorage();
  }

  updateFiltersInStorage() {
    this.storageService.putDataInStorage(this.selectedTab + 'TabFilters', this.selectedFilters);
  }

  resetFilter(event: any, key: string) {
    this.selectedFilters[key] = [];
    this.filterCcdData();

    //update specific filter dropdown options 
    // if (key == 'customer') {
    //   this.customerOptions = this.getDropdownoptions(key, this.filteredCcdData);
    // } else if (key == 'lob') {
    //   this.lobOptions = this.getDropdownoptions(key, this.filteredCcdData);
    // } else if (key == 'owner') {
    //   this.ownerOptions = this.getDropdownoptions(key, this.filteredCcdData);
    // } else if (key == 'coaType') {
    //   this.typeOfCoaOptions = this.getDropdownoptions(key, this.filteredCcdData);
    // }
    this.customerOptions = this.getDropdownoptions('customer', this.filteredCcdData);
    this.lobOptions = this.getDropdownoptions('lob', this.filteredCcdData);
    this.ownerOptions = this.getDropdownoptions('owner', this.filteredCcdData);
    this.typeOfCoaOptions = this.getDropdownoptions('coaType', this.filteredCcdData);
  }

  resetActivate() {
    return (this.selectedFilters['customer'] && this.selectedFilters['customer'].length > 0) ||
      (this.selectedFilters['lob'] && this.selectedFilters['lob'].length > 0) ||
      (this.selectedFilters['owner'] && this.selectedFilters['owner'].length > 0) ||
      (this.selectedFilters['coaType'] && this.selectedFilters['coaType'].length > 0)
      ? true
      : false;
  }

  resetAll() {
    if (this.resetActivate()) {
      this.selectedFilters = { 'customer': [], 'lob': [], 'owner': [], 'coaType': [] };
      this.filterCcdData();
      this.setFilterOptions(this.ccdList, '');
    }
  }

  ccdTabCountUpdation() {
    const tabs = TABS;
    tabs['active'].number_of_records = this.ccdCount.active ?? 0;
    tabs['upcoming'].number_of_records = this.ccdCount.upcoming ?? 0;
    tabs['completed'].number_of_records = this.ccdCount.completed ?? 0;
    tabs['cancelled'].number_of_records = this.ccdCount.cancelled ?? 0;
    tabs['draft'].number_of_records = this.ccdCount.draft ?? 0;

    this.ccdTabs = Object.values(tabs);
  }

  getDateClass(date: string, heading: string | undefined): string {
    if (heading === 'coaEndDate' && this.selectedTab === 'upcoming') {
      const currentDate = new Date();
      const targetDate = new Date(date);

      const timeDifference = targetDate.getTime() - currentDate.getTime();
      const daysDifference = timeDifference / (1000 * 3600 * 24);

      if (daysDifference <= 14) {
        return 'red-text section-title';  // Closer than 2 weeks
      } else if (daysDifference <= 42) {
        return 'amber-text section-title';  // Closer than 6 weeks
      }
    }
    return '';  // More than 6 weeks away
  }

  extractLob(lob: string[]): string {
    if (!Array.isArray(lob) || lob.length === 0) return '';
    const result = lob
      .map(item => item.match(/\(([^)]+)\)/)?.[1])
      .join(', ');
    return result;
  }

  addButton() {
    this.router.navigate(['/dashboard/create-ccd-step1']);
  }
  navigateToReviewCcd(ccdNo:string){
    this.router.navigateByUrl(`/dashboard/review-ccd?ccdNo=${ccdNo}`);
  }

  managerAndCcdOwnerCanModify(ccd: any): boolean {
    return this.currentUser?.role === ROLES['Manager'] ||
      (this.currentUser?.role === ROLES['Owner'] && ccd?.createdBy === this.currentUser?.userId);
  }
  managerAndAnyOwnerCanModify(): boolean {
    return this.currentUser?.role === ROLES['Manager'] || this.currentUser?.role === ROLES['Owner'];
  }
  isPublishButtonDisabled(ccd: any): boolean {
    if (this.managerAndCcdOwnerCanModify(ccd) && ccd?.isApproved && !this.publishLoading) {
      const completedCount = ccd?.departmentList?.reduce((count: number, department: any) => {
        return count + department?.legend?.filter((legend: any) => legend?.name === 'completed').length;
      }, 0);
      return ccd?.departmentList?.length !== completedCount;
    }
    return true;
  }
  isCancelButtonDisabled(ccd: any): boolean {
    return !this.managerAndCcdOwnerCanModify(ccd);
  }
  isReactivateButtonDisabled(): boolean {
    return !this.managerAndAnyOwnerCanModify() || this.reactivateLoading;
  }
  isPursueButtonDisabled(): boolean {
    return !this.managerAndAnyOwnerCanModify();
  }
  isInitiateButtonDisabled(ccd: any): boolean {
    return !(this.managerAndAnyOwnerCanModify() && ccd?.isPursued) || this.initiateLoading;
  }

  showPublishButtonSkeletonLoader(ccdNo: string): boolean {
    return this.publishLoading && this.publishLoadingCcdNo === ccdNo;
  }
  showReactivateButtonSkeletonLoader(ccdNo: string): boolean {
    return this.reactivateLoading && this.reactivateLoadingCcdNo === ccdNo;
  }
  showInitiateButtonSkeletonLoader(ccdNo: string): boolean {
    return this.initiateLoading && this.initiateLoadingCcdNo === ccdNo;
  }

  snoozeNotification() {
    this.ccdService.snoozeAlert()
      .subscribe({
        next: (snoozeRes: any) => {
          this.alertVisibility = false;
          this.toasterService.openToastCcd(Toasts.Types.Success, snoozeRes.settingsRes);
        },
        error: (error) => {
          console.error('Error publishing contract status:', error);
          this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.Snooze.Error);
        }
      });
  }

  selectUpcomingTab(){
    this.selectedTab = 'upcoming';
    const tabs: any = TABS;
    this.onTabSelect(tabs[this.selectedTab], 'ngOnInit');
  }

  publishCcd(ccdNo: string) {
    const reqData = {
      ccdNo: ccdNo,
      type: "publish",
      updateValue: "publish",
      completedAt: this.ccdService.getLocalISOTime(new Date())
    }
    this.publishLoading = true;
    this.publishLoadingCcdNo = ccdNo;
    this.ccdService.updateContractStatus(reqData)
      .subscribe({
        next: () => {
          this.toasterService.openToastCcd(Toasts.Types.Success, customizedMessages('publish', ccdNo), Toasts.Actions.Publish.Title);
          this.removeCcdFromList(ccdNo);
          this.setFilterOptionsAndFilterCcdData();
          this.fetchCcdCount();
          this.publishLoading = false;
          this.publishLoadingCcdNo = '';
        },
        error: (error) => {
          console.error('Error publishing contract status:', error);
          if (error?.status === 423) {
            this.toasterService.openToastCcd(Toasts.Types.Warning, error?.error?.reason, Toasts.Actions.Pursue.WarningTitle);
          } else {
            this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.Publish.Error);
          }
          this.publishLoading = false;
          this.publishLoadingCcdNo = '';
        }
      });
  }

  cancelCcd(ccdNo: string) {
    this.openCancelPopup = true;
    this.cancelCcdNo = ccdNo;
  }

  cancelCcdPopupButtonClick(event: any) {
    this.openCancelPopup = false;
    if (event?.cancellationNotes) {
      const reqData = {
        ccdNo: this.cancelCcdNo,
        type: "cancel",
        updateValue: event?.cancellationNotes
      }
      this.cancelPopupSubmitLoading = true;
      this.ccdService.updateContractStatus(reqData)
        .subscribe({
          next: () => {
            this.toasterService.openToastCcd(Toasts.Types.Success, customizedMessages('cancel', this.cancelCcdNo), Toasts.Actions.Cancel.Title);
            this.removeCcdFromList(this.cancelCcdNo);
            this.setFilterOptionsAndFilterCcdData();
            this.fetchCcdCount();
            this.cancelPopupSubmitLoading = false;
          },
          error: (error) => {
            if (error?.status === 423) {
              this.toasterService.openToastCcd(Toasts.Types.Warning, error?.error?.reason, Toasts.Actions.Pursue.WarningTitle);
            } else {
              this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.Cancel.Error);
            }
            this.cancelPopupSubmitLoading = false;
          }
        });
    }
  }

  onSwitchPursueCcd(event: any, ccd: any) {
    const reqData = {
      ccdNo: ccd.ccdNo,
      type: "pursue",
      updateValue: event.isSwitchActive ? "1" : "0"
    }
    this.updateCcdPursueStatus(ccd.ccdNo, event.isSwitchActive,'success');
    this.ccdService.updateContractStatus(reqData)
      .subscribe({
        next: () => {
        },
        error: (error) => {
          console.error('Error pursuing contract status:', error);
          if (error?.status === 423) {
            this.toasterService.openToastCcd(Toasts.Types.Warning, error?.error?.reason, Toasts.Actions.Pursue.WarningTitle);
          } else {
            this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.Pursue.Error);
          }
          this.updateCcdPursueStatus(ccd?.ccdNo, event.isSwitchActive,'failure');
        }
      });
  }
  updateCcdPursueStatus(ccdNo: string, isSwitchActive: boolean, status: string) {
    this.filteredCcdData = this.filteredCcdData.map((ccdData: any) => {
      if (ccdData.ccdNo === ccdNo) {
        ccdData.isPursued = status === 'success' ? isSwitchActive : !isSwitchActive;
      }
      return ccdData;
    });
  }

  initiateCcd(ccdNo: string) {
    const reqData = {
      ccdNo: ccdNo
    }
    this.initiateLoading = true;
    this.initiateLoadingCcdNo = ccdNo;
    this.ccdService.initiateUpcomingContract(reqData)
      .subscribe({
        next: ({ ccdData }: any) => {
          this.toasterService.openToastCcd(Toasts.Types.Success, customizedMessages('initiate', ccdNo), Toasts.Actions.Initiate.Title);
          this.removeCcdFromList(ccdNo);
          this.setFilterOptionsAndFilterCcdData();
          this.fetchCcdCount();
          this.router.navigate(['/dashboard/create-ccd-step1'], {
            queryParams: { ccdNo: ccdData},
          });
          this.initiateLoading = false;
          this.initiateLoadingCcdNo = '';
        },
        error: (error) => {
          console.error('Error initiating contract:', error);
          if (error?.status === 423) {
            this.toasterService.openToastCcd(Toasts.Types.Warning, error?.error?.reason, Toasts.Actions.Initiate.WarningTitle);
          } else {
            this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.Initiate.Error);
          }
          this.initiateLoading = false;
          this.initiateLoadingCcdNo = '';
        }
      });
  }

  reactivateCcd(ccdNo: string) {
    const reqData = {
      ccdNo: ccdNo,
      type: "reactivate",
      updateValue: ""
    }
    this.reactivateLoading = true;
    this.reactivateLoadingCcdNo = ccdNo;
    this.ccdService.updateContractStatus(reqData)
      .subscribe({
        next: () => {
          this.router.navigate(['/dashboard/create-ccd-step1'], {
            queryParams: { ccdNo: ccdNo },
          });
          this.reactivateLoading = false;
          this.reactivateLoadingCcdNo = '';
        },
        error: (error) => {
          console.error('Error reactivating contract status:', error);
          this.reactivateLoading = false;
          this.reactivateLoadingCcdNo = '';
          if (error?.status === 423) {
            this.toasterService.openToastCcd(Toasts.Types.Warning, error?.error?.reason, Toasts.Actions.Reactivate.WarningTitle);
          } else {
            this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.Reactivate.Error);
          }
        }
      });
  }

  resumeCcd(ccdNo: string) {
    this.router.navigate(['/dashboard/create-ccd-step1'], {
      queryParams: { ccdNo: ccdNo },
    });
  }

  deleteCcd(ccdNo: string) {
    this.openDeleteDraftPopup = true;
    this.deleteCcdNo = ccdNo;
  }

  deleteCcdPopupButtonClick(event: any) {
    this.openDeleteDraftPopup = false;
    if (event?.isDeleteCcd) {
      if (this.deleteCcdNo) {
        const reqData = {
          ccdNo: this.deleteCcdNo,
          type: "discard",
          updateValue: ''
        }
        this.deletePopupDeleteLoading = true;
        this.ccdService.updateContractStatus(reqData).subscribe({
          next: () => {
            this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.DeleteCcd.Success, Toasts.Actions.DeleteCcd.Title);
            this.removeCcdFromList(this.deleteCcdNo);
            this.setFilterOptionsAndFilterCcdData();
            this.fetchCcdCount();
            this.deletePopupDeleteLoading = false;
          },
          error: (error) => {
            console.error('Error deleting CCD:', error);
            this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.DeleteCcd.Error);
            this.deletePopupDeleteLoading = false;
          }
        });
      }
      else {
        this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.DeleteCcd.Success, Toasts.Actions.DeleteCcd.Title);
        this.router.navigate(['/dashboard']);
      }
    }
  }

  removeCcdFromList(ccdNo: string) {
    this.ccdList = this.ccdList.filter((ccd: any) => {
      if (ccd.ccdNo != ccdNo) {
        return ccd;
      }
    });
  }
}


import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ActionPanelModule, ButtonModule, CheckboxModule, DatePickerModule, DropdownModule, FileUploadModule, InputModule, LinkModule, ModalModule, PageTitleModule } from 'snl-complib';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { TYPEOFCOA, LAW, ROLES } from 'src/app/constant/constant';
import { CcdService } from 'src/app/services/ccd.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { finalize, forkJoin, Subject, take } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { Toasts } from 'src/app/constant/toast.messages';
import { CancelEditCcdPopupComponent } from 'src/app/components/cancel-edit-ccd-popup/cancel-edit-ccd-popup.component';
import { UploadFilesComponent } from "../../components/upload-files/upload-files.component";
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-edit-ccd-details',
  standalone: true,
  imports: [CancelEditCcdPopupComponent, CommonModule, ReactiveFormsModule, SvgIconComponent, DropdownModule, DatePickerModule, InputModule, ButtonModule, ActionPanelModule, PageTitleModule, LinkModule, ModalModule, FileUploadModule, CheckboxModule, UploadFilesComponent],
  templateUrl: './edit-ccd-details.component.html',
  styleUrl: './edit-ccd-details.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class EditCcdDetailsComponent {
  open: boolean = false;
  public ccdForm!: FormGroup;
  public savedFormObj: any = [];
  public isCcdRefCheckClicked: boolean = false;
  public coaTypeOptions: any;
  public lawOptions: any;
  public lobOptions: any = [];
  public managerOptions: any = [];
  private typeOfCoa: any = TYPEOFCOA;
  private law: any = LAW;
  public setDueDate: Date = new Date();
  public minDueDate: Date = new Date();
  public maxDueDate: Date = new Date();
  public setContractStartDate: Date = new Date();
  public setContractEndDate: Date = new Date();
  public minContractDate: Date = new Date();
  public maxContractDate: Date = new Date();
  public isCcdRefValidated: boolean | undefined = undefined;
  public openCancelEditCcdPopup: boolean = false;
  public deleteCcdNo: string = '';
  public ccdNo: any;
  public coaTypeObj: any = [];
  public lawObj: any = [];
  public managerObj: any = [];
  public lobObj: any = [];
  public ownerAddedDocuments: any = [];
  public isCcdRefDisabled: boolean = true;
  public isNewClauseChecked: boolean = false;
  public nextLoading: boolean = false;
  public saveExitLoading: boolean = false;
  public fetchCcdDataLoading: boolean = false;
  public currentUser: any = [];
  public ccdRes: any;
  private lobDataFetched = new Subject<boolean>();
  private managersDataFetched = new Subject<boolean>();
  private ccdDataFetched = new Subject<boolean>();
  public fieldsLoading: boolean = true;

  constructor(
    private ccdService: CcdService,
    private validationService: ValidationService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.makeCcdForm();
    this.fetchTypeOfCoaOptions();
    this.fetchLawOptions();
    this.fetchLobOptions();
    this.fetchManagerOptions();
    this.route.queryParams.subscribe((params) => {
      this.ccdNo = params['ccdNo'];
      if (this.ccdNo) {
        this.fetchBackendUsersData();
        this.fetchCCDData();
      }
      this.setDates();
    });
    this.executeCodeAfterDataFetched();
  }

  fetchBackendUsersData() {
    //get current user data
    this.authService.getBackendUserData().subscribe((data: any) => {
      this.currentUser = data;
      //set header data
      const headerData = {
        headerTitle: `Edit CCD#${this.ccdNo} details`,
        backButtonNavigateTo: `dashboard/${data?.role === ROLES['Manager'] && this.authService?.isDepartmentalDependency ? 'manage-ccd' : 'review-ccd'}?ccdNo=${this.ccdNo}`,
        breadCrumbList: [
          { id: '1', label: 'Dashboard', navigateTo:'dashboard',isClickable: true },
          { id: '3', label: `Edit CCD#${this.ccdNo} details`, isClickable: false }
        ]
      }
      this.navigationService.setHeaderData(headerData);
    });
  }

  makeCcdForm() {
    this.ccdForm = new FormGroup({
      ccdNo: new FormControl('', [Validators.required, Validators.minLength(3)]),
      coaType: new FormControl('', [Validators.required]),
      dueBy: new FormControl('', [Validators.required]),
      dueByOffset: new FormControl(''),
      customer: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      lob: new FormControl([], [Validators.required]),
      service: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      product: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      estAnnualVolumeMin: new FormControl('0', [Validators.pattern(/^\d+$/), Validators.maxLength(15),  this.minMaxValidator('estAnnualVolumeMin', 'estAnnualVolumeMax')]),
      estAnnualFreightMin: new FormControl('0', [Validators.pattern(/^\d+$/), Validators.maxLength(15), this.minMaxValidator('estAnnualFreightMin', 'estAnnualFreightMax')]),
      estAnnualVolumeMax: new FormControl('', [Validators.pattern(/^\d+$/), Validators.required, Validators.minLength(3), Validators.maxLength(15)]),
      estAnnualFreightMax: new FormControl('', [Validators.pattern(/^\d+$/), Validators.required, Validators.minLength(3), Validators.maxLength(15)]),
      chartererPartyForm: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(36)]),
      law: new FormControl('', [Validators.required]),
      manager: new FormControl('', [Validators.required]),
      managerId: new FormControl('', [Validators.required]),
      ccdRefField: new FormControl('', [Validators.minLength(7), Validators.maxLength(108)]),
      ccdRef: new FormControl(''),
      coaStartDate: new FormControl('', [Validators.required]),
      coaEndDate: new FormControl('', [Validators.required]),
      coaStartDateOffset: new FormControl(''),
      coaEndDateOffset: new FormControl(''),
      isNewClause: new FormControl(false, [Validators.required])
    });

    // call validation function minMaxValidator on entering data in max fields
    this.ccdForm.get('estAnnualVolumeMax')?.valueChanges.subscribe(() => {
      this.ccdForm.get('estAnnualVolumeMin')?.updateValueAndValidity();
    });
    this.ccdForm.get('estAnnualFreightMax')?.valueChanges.subscribe(() => {
      this.ccdForm.get('estAnnualFreightMin')?.updateValueAndValidity();
    });
  }

  minMaxValidator(minField: string, maxField: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const minControl = control.root.get(minField);
      const maxControl = control.root.get(maxField);
  
      if (minControl && maxControl) {
        const minVal = Number(minControl.value);
        const maxVal = Number(maxControl.value);
  
        if (minVal > maxVal) {
          if(minField === 'estAnnualVolumeMin' && maxField === 'estAnnualVolumeMax'){
            return { 'minMaxVolume': true };
          }
          else if(minField === 'estAnnualFreightMin' && maxField === 'estAnnualFreightMax'){
            return { 'minMaxFreight': true };
          }
        }
      }
  
      return null;
    };
  }

  put0InMinFieldsIfEmpty() {
    const minFields = ['estAnnualVolumeMin', 'estAnnualFreightMin'];
    minFields.forEach(minField => {
      const control = this.ccdForm.get(minField);
      if (control && control.value === '') {
        control.setValue('0');
      }
    });
  }

  setDates() {
    this.setDueDateFunc();
    this.setContractDateFunc();
  }
  setDueDateFunc() {
    const today = new Date();
    this.minDueDate.setDate(today.getDate() + 6);
    this.maxDueDate.setDate(today.getDate() + 42);
    this.setDueDate.setDate(today.getDate() + 7);
    this.setDueDate.setHours(23);
    this.setDueDate.setMinutes(59);
    this.setDueDate.setSeconds(59);
    this.ccdForm.patchValue({
      dueBy: this.ccdService.getLocalISOTime(new Date(this.setDueDate)), 
      dueByOffset: new Date().getTimezoneOffset()
    });
  }
  setContractDateFunc() {
    const today = new Date();
    this.minContractDate.setDate(today.getDate());
    this.maxContractDate.setDate(today.getDate() + 1000);
    this.setContractStartDate.setDate(today.getDate());
    this.setContractEndDate.setDate(today.getDate() + 30);
    this.setContractStartDate.setHours(0);
    this.setContractStartDate.setMinutes(0);
    this.setContractStartDate.setSeconds(0);
    this.setContractEndDate.setHours(23);
    this.setContractEndDate.setMinutes(59);
    this.setContractEndDate.setSeconds(59);
    this.ccdForm.patchValue({
      coaStartDate: this.ccdService.getLocalISOTime(new Date(this.setContractStartDate)),
      coaEndDate: this.ccdService.getLocalISOTime(new Date(this.setContractEndDate)),
      coaStartDateOffset: new Date().getTimezoneOffset(),
      coaEndDateOffset: new Date().getTimezoneOffset()
    });
  }

  fetchTypeOfCoaOptions() {
    this.coaTypeOptions = Object.keys(this.typeOfCoa).map(key => ({
      id: key,
      name: this.typeOfCoa[key]
    }));
  }
  fetchLawOptions() {
    this.lawOptions = Object.keys(this.law).map(key => ({
      id: key,
      name: this.law[key]
    }));
  }
  fetchLobOptions() {
    this.ccdService.fetchLobList().subscribe({
      next: ({ lobList }: any) => {
        this.lobOptions = lobList;
        this.lobDataFetched.next(true);
      },
      error: (error) => {
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        console.error('Error fetching Lob options:', error);
      }
    });
  }
  fetchManagerOptions() {
    const reqData = {
      type: "MANAGER",
      includeADUsers: false
    }
    this.ccdService.fetchUsersData(reqData).subscribe({
      next: ({ userData }: any) => {
        this.managerOptions = userData.map((user: any) => ({
          id: user?.userId,
          name: user.name
        }));
        this.managersDataFetched.next(true);
      },
      error: (error) => {
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        console.error('Error fetching Manager options:', error);
      }
    });
  }

  executeCodeAfterDataFetched() {
    forkJoin([
      this.lobDataFetched.pipe(take(1)),
      this.managersDataFetched.pipe(take(1)),
      this.ccdDataFetched.pipe(take(1))
    ]).subscribe({
      next: () => {
        this.populateCcdData(this.ccdRes);
        this.fieldsLoading = false;
      },
      error: (error) => {
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        console.error('Error fetching data:', error);
        this.fieldsLoading = false;
      }
    });

  }

  populateCcdData(ccdObj: any) {
    if (ccdObj.coaType) {
      this.coaTypeObj = [{
        "id": ccdObj.coaType,
        "name": this.typeOfCoa[ccdObj.coaType]
      }];
    }
    if (ccdObj.law) {
      this.lawObj = [{
        "id": ccdObj.law,
        "name": this.law[ccdObj.law]
      }];
    }
    if (ccdObj.lob.length > 0) {
      this.lobObj = ccdObj.lob.map((val: any) => ({ "id": val.split("(")[1].split(")")[0], "name": val }));
    }
    if (ccdObj.managerId && this.isFetchedManagerPresentInOptions(ccdObj.managerId)) {
      this.managerObj = [{ "id": ccdObj.managerId, "name": ccdObj.manager }];
    }

    this.setDueDate = new Date(ccdObj.dueBy);
    this.setContractStartDate = new Date(ccdObj.coaStartDate);
    this.setContractEndDate = new Date(ccdObj.coaEndDate);
    this.isNewClauseChecked = ccdObj.isNewClause;
    this.isCcdRefValidated = ccdObj?.ccdRef ? true : undefined;
    let ccdRefField = ccdObj?.ccdRefField ? ccdObj.ccdRefField : '';

    let obj = {
      ccdNo: ccdObj.ccdNo,
      coaType: ccdObj.coaType,
      dueBy: this.ccdService.getLocalISOTime(new Date(ccdObj.dueBy)),
      dueByOffset: new Date().getTimezoneOffset(),
      customer: ccdObj.customer,
      lob: ccdObj.lob,
      service: ccdObj.service,
      product: ccdObj.product,
      estAnnualVolumeMin: ccdObj.estAnnualVolumeMin?.toString() ?? "0",
      estAnnualFreightMin: ccdObj.estAnnualFreightMin?.toString() ?? "0",
      estAnnualVolumeMax: ccdObj.estAnnualVolumeMax?.toString() ?? "",
      estAnnualFreightMax: ccdObj.estAnnualFreightMax?.toString() ?? "",
      chartererPartyForm: ccdObj.chartererPartyForm,
      law: ccdObj.law,
      manager: this.isFetchedManagerPresentInOptions(ccdObj.managerId) ? ccdObj.manager : '',
      managerId: this.isFetchedManagerPresentInOptions(ccdObj.managerId) ? ccdObj.managerId : '',
      ccdRefField: ccdRefField,
      ccdRef: ccdObj.ccdRef,
      isNewClause: ccdObj.isNewClause,
      coaStartDate: this.ccdService.getLocalISOTime(new Date(this.setContractStartDate)),
      coaEndDate: this.ccdService.getLocalISOTime(new Date(this.setContractEndDate)),
      coaStartDateOffset: new Date().getTimezoneOffset(),
      coaEndDateOffset: new Date().getTimezoneOffset()
    };

    this.ccdForm.patchValue(obj);
  }

  isFetchedManagerPresentInOptions(managerId:string) {
    return this.managerOptions.some((option: any) => option.id === managerId);
  }

  fetchCCDData() {
    this.fetchCcdDataLoading = true;
    this.ccdService.fetchCcdData({ "ccdNo": this.ccdNo }).subscribe({
      next: ({ ccdRes, ownerAddedDocuments }: any) => {
        this.ownerAddedDocuments = ownerAddedDocuments;
        if (ccdRes && ccdRes.length > 0) {
          this.ccdRes = ccdRes[0];
          this.ccdDataFetched.next(true);
        }
        this.fetchCcdDataLoading = false;
      },
      error: (error) => {
        console.error('Error fetching CCD data:', error);
        this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.err);
        if ([401, 404].includes(error?.status)) {
          this.router.navigateByUrl('dashboard/review-ccd?ccdNo=' + this.ccdNo);
        }
        this.fetchCcdDataLoading = false;
      }
    });
  }

  applySelectedCoaType(event: any) {
    this.ccdForm.patchValue({ coaType: event?.selectedRecords?.id });
  }

  resetCoaType() {
    this.ccdForm.patchValue({ coaType: '' });
  }

  applySelectedLaw(event: any) {
    this.ccdForm.patchValue({ law: event?.selectedRecords?.id });
  }

  resetLaw() {
    this.ccdForm.patchValue({ law: '' });
  }

  applySelectedLob(event: any) {
    const selectedLobNames = event?.selectedRecords?.map((record: any) => record.name) || [];
    this.ccdForm.patchValue({ lob: selectedLobNames });
  }

  resetLob() {
    this.ccdForm.patchValue({ lob: [] });
  }

  applySelectedManager(event: any) {
    this.ccdForm.patchValue({ 'managerId': event?.selectedRecords?.id });
    this.ccdForm.patchValue({ 'manager': event?.selectedRecords?.name });
  }

  resetManager() {
    this.ccdForm.patchValue({ 'managerId': '' });
    this.ccdForm.patchValue({ 'manager': '' });
  }

  selectedDueDate(event: any) {
    event.setHours(23);
    event.setMinutes(59);
    event.setSeconds(59);
    this.ccdForm.patchValue({ dueBy: this.ccdService.getLocalISOTime(event), dueByOffset: new Date().getTimezoneOffset() });
  }

  selectedContractPeriod(event: any) {
    event?.endDate.setHours(23);
    event?.endDate.setMinutes(59);
    event?.endDate.setSeconds(59);
    this.ccdForm.patchValue({ coaStartDate: this.ccdService.getLocalISOTime(event?.startDate), coaStartDateOffset: new Date().getTimezoneOffset() });
    this.ccdForm.patchValue({ coaEndDate: this.ccdService.getLocalISOTime(event?.endDate), coaEndDateOffset: new Date().getTimezoneOffset() });
  }

  withNewClauseClick(event: any) {
    this.ccdForm.patchValue({ isNewClause: event.target.checked });
  }

  ccdRefChange() {
    this.isCcdRefValidated = undefined;
    this.ccdForm.get('ccdRef')?.patchValue('');
    if (this.ccdForm.get('ccdRefField')?.invalid || (this.ccdForm.get('ccdRefField')?.value === '')) {
      this.isCcdRefDisabled = true;
    }
    else {
      this.isCcdRefDisabled = false;
    }
  }

  checkCcdRef() {
    this.trimFormFields();
    this.isCcdRefCheckClicked = true;
    const reqData = {
      ccdNo: this.ccdForm.get('ccdRefField')?.value
    }
    this.ccdService.fetchCcdReference(reqData).pipe(
      finalize(() => {
        this.isCcdRefCheckClicked = false;
      })
    ).subscribe({
      next: ({ ccdRes }: any) => {
        this.isCcdRefValidated = true;
        this.isCcdRefDisabled = true;
        this.ccdForm.get('ccdRefField')?.patchValue(ccdRes?.ccdNo + ' ' + ccdRes?.customer);
        this.ccdForm.get('ccdRef')?.patchValue(ccdRes?.ccdNo);
      },
      error: (error) => {
        this.isCcdRefValidated = false;
        this.ccdForm.get('ccdRef')?.patchValue('');
        console.error('Error validating CCD:', error);
      }
    });
  }

  deleteCcdRefField() {
    const { ccdRefField, ...newObj } = this.ccdForm.value;
    return newObj;
  }

  showError(field: string) {
    let errorMsg = this.validationService.showInputError(field, this.ccdForm);
    return errorMsg;
  }

  trimFormFields() {
    Object.keys(this.ccdForm.controls).forEach(key => {
      const control = this.ccdForm.get(key);
      if (control && typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  }

  onSubmit() {
    if (this.ccdForm.valid) {
      this.put0InMinFieldsIfEmpty();
      this.trimFormFields();
      const formObj = this.deleteCcdRefField();
      const reqData = {
        contractData: formObj,
        type: "submit"
      };
      this.nextLoading = true;
      this.cdr.detectChanges();
      this.ccdService.createCcd(reqData).subscribe({
        next: ({ createdContract }: any) => {
          if(this.currentUser?.role === ROLES['Manager'] && this.authService?.isDepartmentalDependency){
            this.router.navigateByUrl('dashboard/manage-ccd?ccdNo=' + createdContract.ccdNo);
          }
          else {
            this.router.navigateByUrl('dashboard/review-ccd?ccdNo=' + createdContract.ccdNo);
          }
          this.nextLoading = false;
        },
        error: (error) => {
          this.nextLoading = false;
          this.cdr.detectChanges();
          if (error?.status === 423) {
            this.toasterService.openToastCcd(Toasts.Types.Warning, error?.error?.reason);
          } else {
            this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
          }
          console.error('Error creating/updating CCD:', error);
        }
      });
    }
  }
  cancelCcdPopup() {
    this.openCancelEditCcdPopup = true;
  }

  cancelCcd(event: any) {
    this.openCancelEditCcdPopup = false;
    if (event?.isCancelCcd) {
      if(this.currentUser?.role === ROLES['Manager'] && this.authService?.isDepartmentalDependency) {
        this.router.navigateByUrl('dashboard/manage-ccd?ccdNo=' + this.ccdNo);
      }
      else {
        this.router.navigateByUrl('dashboard/review-ccd?ccdNo=' + this.ccdNo);
      }
    }
  }
}



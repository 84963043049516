import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MsalService } from '@azure/msal-angular';
import { first, map } from 'rxjs/operators';
import { ROLES } from '../constant/constant';
import { environment } from 'src/environments/environment';
import { CcdService } from '../services/ccd.service';
import { StorageService } from '../services/storage.service';

export const validUserGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const msalService = inject(MsalService);
  const ccdService = inject(CcdService);
  const storageService = inject(StorageService);

  return authService.getBackendUserData().pipe(
    first(user => user !== null), // Wait for the first non-null value
    map(user => {
      if (user && ([ROLES['Manager'], ROLES['Owner'], ROLES['DepartmentUser'], ROLES['Other']].includes(user?.role) || user?.length === 0)) {
        console.log('valid user 1');
        return true;
      } else {
        console.log('valid user 2');
        //logout
        msalService.logoutRedirect({
          postLogoutRedirectUri: environment.redirectUri
        });
        localStorage.clear();
        ccdService.updateAuditLogs({ type: 'logout' }).subscribe((response) => {
          storageService.putDataInStorage('loggedIn', 'false');
        });
        return false;
      }
    })
  );
};
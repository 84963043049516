
<div class="alertMain">
   @if (alertVisibility) {
        <div class="alertIn body-b1">
            <div class="alertInner">
                <svg-icon src="assets/icons/warning.svg"></svg-icon>
                <span>{{alert.alertMessage}}</span>
            </div>
            <a href="javascript:void(0)" (click)="selectUpcomingTab()">{{alert.linkText}}</a>
        </div>
        <a href="javascript:void(0)" (click)="snoozeNotification()" class="section-title">Snooze alert</a>
    }
</div>

<section class="dashboard-wrapper">
    <header class="dashboard-header">
        <div class="TabsHolder">
            <app-tabs [tabs]="ccdTabs" [selectedTab]="selectedTab" (onSelect)="onTabSelect($event)"></app-tabs>
        </div>
    </header>
    @if (loading) {
        <div class="dashboard-content">
            <div class="ccd-filters-main">
                <div class="ccd-filters">
                    <span class="filters-title section-title">Filters</span>
                    <span class="reset-filters section-title">Reset All</span>
                    <div class="filter" *ngFor="let _ of [].constructor(4)">
                        <span class="Stolt-Skeleton"></span>
                    </div>
                </div>
                @if (['active', 'completed', 'cancelled'].includes(selectedTab)) {
                <app-legend-panel></app-legend-panel>
                }
            </div>
            <div class="ccd-table" [ngClass]="['upcoming','draft'].includes(selectedTab) ? 'NoLegendSticky' : ''">
                <snl-table class="TableSkeleton">
                    <snl-row class="ColWidthMain bg-theader">
                        @for (tHeading of tableHeadings | slice:0:7; track tHeading) {
                        <snl-th class="HeightManager">
                            <span>{{ tHeading?.header }}</span>
                        </snl-th>
                        }
                        @if (['active', 'completed', 'cancelled'].includes(selectedTab)) {
                            @for (dHeading of departmentHeadings; track dHeading) {
                            <snl-th class="HeightManager iconCol120">
                                <span>{{ dHeading?.header }}</span>
                            </snl-th>
                            }
                        }
                        @for (tHeading of tableHeadings | slice:7; track tHeading) {
                        <snl-th class="HeightManager">
                            <span>{{ tHeading?.header }}</span>
                        </snl-th>
                        }
                    </snl-row>
                    <snl-row class="ColWidthMain" *ngFor="let _ of [].constructor(10)">
                        @for (tHeading of tableHeadings | slice:0:7; track tHeading) {
                        <snl-td><span class="Stolt-Skeleton"></span></snl-td>
                        }
                        @if (['active', 'completed', 'cancelled'].includes(selectedTab)) {
                            @for (dHeading of departmentHeadings; track dHeading) {
                            <snl-td><span class="Stolt-Skeleton"></span></snl-td>
                            }
                        }
                        @for (tHeading of tableHeadings | slice:7; track tHeading) {
                        <snl-td><span class="Stolt-Skeleton"></span></snl-td>
                        }
                    </snl-row>
                </snl-table>
            </div>
        </div>
    }
    @else if (ccdList.length === 0) {
        <app-no-data-found-screen [selectedTab]="selectedTab"></app-no-data-found-screen>
    }
    @else {
        <div class="dashboard-content">
            <div class="ccd-filters-main">
                <div class="ccd-filters">
                    <span class="filters-title section-title">Filters</span>
                    <span class="" [ngClass]="!resetActivate() ? 'anchor-disable' : ''">
                        <a href="javascript:void(0);"
                        class="section-title ResetAllTxt" (click)="resetAll()">Reset all
                        </a>
                    </span>
                    <div class="filter">
                        <snl-dropdown
                        [label]="'Customer'"
                        [preSelectedItems]="selectedFilters.customer"
                        [buttonPrimary]="'Apply'"
                        [buttonSecondary]="'Reset'"
                        [options]="customerOptions"
                        (selectedValues)="applySelectedValues($event, 'customer')"
                        (resetDropdown)="resetFilter($event, 'customer')"
                        [multiple]="true"
                        ></snl-dropdown>
                    </div>
                    <div class="filter">
                        <snl-dropdown
                        [label]="'LOB'"
                        [preSelectedItems]="selectedFilters.lob"
                        [buttonPrimary]="'Apply'"
                        [buttonSecondary]="'Reset'"
                        [options]="lobOptions"
                        (selectedValues)="applySelectedValues($event, 'lob')"
                        (resetDropdown)="resetFilter($event, 'lob')"
                        [multiple]="true"
                        ></snl-dropdown>
                    </div>
                    <div class="filter">
                        <snl-dropdown
                        [label]="'Owner'"
                        [preSelectedItems]="selectedFilters.owner"
                        [buttonPrimary]="'Apply'"
                        [buttonSecondary]="'Reset'"
                        [options]="ownerOptions"
                        (selectedValues)="applySelectedValues($event, 'owner')"
                        (resetDropdown)="resetFilter($event, 'owner')"
                        [multiple]="true"
                        ></snl-dropdown>
                    </div>
                    <div class="filter">
                        <snl-dropdown
                        [label]="'Type of COA'"
                        [preSelectedItems]="selectedFilters.coaType"
                        [buttonPrimary]="'Apply'"
                        [buttonSecondary]="'Reset'"
                        [options]="typeOfCoaOptions"
                        (selectedValues)="applySelectedValues($event, 'coaType')"
                        (resetDropdown)="resetFilter($event, 'coaType')"
                        [multiple]="true"
                        ></snl-dropdown>
                    </div>
                </div>
                @if (['active', 'completed', 'cancelled'].includes(selectedTab)) {
                    <app-legend-panel></app-legend-panel>
                }
            </div> 
            <div class="ccd-table" [ngClass]="['upcoming','draft'].includes(selectedTab) ? 'NoLegendSticky' : ''">
                <snl-table
                    [useTopScroll]="false">
                    <snl-row class="ColWidthMain bg-theader">
                        @for (tHeading of tableHeadings | slice:0:7; track tHeading) {
                        <snl-th class="HeightManager">
                            <span>{{ tHeading?.header }}</span>
                        </snl-th>
                        }
                        @if (['active', 'completed', 'cancelled'].includes(selectedTab)) {
                            @for (dHeading of departmentHeadings; track dHeading) {
                            <snl-th class="HeightManager iconCol120">
                                <span>{{ dHeading?.header }}</span>
                            </snl-th>
                            }
                        }
                        @for (tHeading of tableHeadings | slice:7; track tHeading) {
                        <snl-th class="HeightManager">
                            <span>{{ tHeading?.header }}</span>
                        </snl-th>
                        }
                    </snl-row>
                        @for (ccd of filteredCcdData; track ccd) {
                        <snl-row class="ColWidthMain">
                            @for (heading of tableHeadings | slice:0:7; track heading) {
                                <snl-td>
                                @if (heading.field === 'ccdNo') {
                                    @if(selectedTab != 'draft'){
                                    <span class="section-title ccdNo" (click)="navigateToReviewCcd(ccd?.ccdNo)">{{ ccd[heading.field] }}</span>
                                    }
                                    @else if (ccd?.['ccdNoFormat'] === 'actual') {
                                    <span>{{ ccd[heading.field] }}</span>
                                    }
                                    @else{
                                        <span>NA</span>
                                    }
                                }
                                @else if (['createdAt', 'submittedAt','coaStartDate', 'coaEndDate', 'dueBy'].includes(heading.field)) {
                                <span [ngClass]="getDateClass(ccd[heading.field], heading?.field)">{{ ccd[heading.field] | date: 'd MMM y' }}</span>
                                }
                                @else if (heading.field === 'lob') {
                                <span>{{ extractLob(ccd[heading.field]) }}</span>
                                }
                                @else if (heading.field === 'coaType') {
                                <span>{{ typeOfCOA[ccd[heading.field]] }}</span>
                                }
                                @else {
                                <span *ngIf="heading?.field">{{ ccd[heading.field] }}</span>
                                }
                            </snl-td>
                            }
                            @if (['active', 'completed', 'cancelled'].includes(selectedTab)) {
                                @if (ccd?.updatedDepartmentList?.length > 0) {
                                    @for (department of ccd?.updatedDepartmentList; track department) {
                                    <snl-td class="iconCol120" *ngIf="department?.department != 'OWNER'">
                                        <span class="legendIconMn">
                                            @if (department?.legend?.length > 0) {
                                                @for (legend of department?.legend; track legend) {
                                                <span>
                                                    <svg-icon src="assets/icons/{{legend?.name}}.svg"></svg-icon>
                                                </span>
                                                }
                                            }
                                            @else {
                                                <span>
                                                    <svg-icon src="assets/icons/na.svg"></svg-icon>
                                                </span>
                                            }
                                        </span>
                                    </snl-td>
                                    }
                                }
                                @else {
                                    @for (dHeading of departmentHeadings; track dHeading) {
                                    <snl-td></snl-td>
                                    }
                                }
                            }
                            @switch (selectedTab) {
                                @case ('active') {
                                    <snl-td>
                                        <snl-button class="BtnCstm" [ngClass]="{'Stolt-Skeleton': showPublishButtonSkeletonLoader(ccd?.ccdNo)}" [type]="'tertiary'" [size]="'large'" icon="fa fa-arrow-right" (buttonClick)="publishCcd(ccd?.ccdNo)" [disabled]="isPublishButtonDisabled(ccd)">Publish</snl-button>
                                    </snl-td>
                                    <snl-td>
                                        <snl-button class="BtnCstm" [type]="'tertiary'" [size]="'large'" icon="fa fa-xmark" (buttonClick)="cancelCcd(ccd?.ccdNo)" [disabled]="isCancelButtonDisabled(ccd)">Cancel</snl-button>
                                    </snl-td>
                                }
                                @case ('upcoming') {
                                <snl-td class="SwitchColMn">
                                    <snl-switch [ngClass]="{'disabled-toggle': isPursueButtonDisabled() || ccd.isReInitiated}" (toggle)="onSwitchPursueCcd($event,ccd)" [toggleState]="ccd?.isPursued" [activeText]="'Yes'" [inActiveText]="'No'" [disabled]="isPursueButtonDisabled() || ccd.isReInitiated"></snl-switch>
                                </snl-td>
                                    @if(!ccd.isReInitiated){
                                        <snl-td>
                                        <snl-button class="BtnCstm" [ngClass]="{'Stolt-Skeleton': showInitiateButtonSkeletonLoader(ccd?.ccdNo)}" [type]="'tertiary'" [size]="'large'" icon="fa fa-arrow-right" (buttonClick)="initiateCcd(ccd?.ccdNo)" [disabled]="isInitiateButtonDisabled(ccd)">Initiate</snl-button>
                                        </snl-td>}
                                    @else {
                                        <snl-td>
                                            <p>Initiated By {{ccd.reInitiatedByName}} </p>
                                            <p>{{ccd?.reInitiatedAt | date: 'd MMM y' }} </p>
                                        </snl-td>
                                    }
                                }
                                @case ('completed') {
                                    <snl-td class="DatePublishedCol">{{ccd?.completedAt | date: 'd MMM y' }}</snl-td>
                                }
                                @case ('cancelled') {
                                    <snl-td class="CancellationNotesCol">
                                        <snl-tooltip [position]="'top'">
                                            <span class="body-b1">{{ccd?.canceledReason}}</span>
                                            <div tooltipContent class="TpCustomization fixed240"> 
                                                <div class="body-b1">
                                                    {{ccd?.canceledReason}}
                                                </div>
                                            </div>
                                        </snl-tooltip>
                                    </snl-td>
                                    <snl-td class="ReactivateCol">
                                        <snl-button class="BtnCstm" [ngClass]="{'Stolt-Skeleton': showReactivateButtonSkeletonLoader(ccd?.ccdNo)}" [type]="'tertiary'" [size]="'large'" icon="fa fa-arrow-right" (buttonClick)="reactivateCcd(ccd?.ccdNo)" [disabled]="isReactivateButtonDisabled()">Reactivate</snl-button>
                                    </snl-td>
                                }
                                @case ('draft') {
                                    <snl-td>
                                        <snl-button class="BtnCstm" [type]="'tertiary'" [size]="'large'" icon="fa fa-arrow-right" (buttonClick)="resumeCcd(ccd?.ccdNo)">Resume</snl-button>
                                    </snl-td>
                                    <snl-td>
                                        <snl-button class="BtnCstm" [type]="'tertiary'" [size]="'large'" icon="fa fa-arrow-right" (buttonClick)="deleteCcd(ccd?.ccdNo)">Delete</snl-button>
                                    </snl-td>
                                }
                                @default {}
                            }
                        </snl-row>
                        }
                </snl-table>
            </div>
        </div>
    } 
</section>

<!-- Sticky Footer Bottom Action Panel -->
<footer class="FixedFooterBar">
    <snl-action-panel>
      <div left-side class="df-c">
        <snl-button [type]="'primary'" [size]="'large'" icon="fa fa-plus" (buttonClick)="addButton()" [disabled]="![roles['Manager'],roles['Owner']].includes(currentUser?.role)">{{buttonLabel}}</snl-button>
      </div>
    </snl-action-panel>
</footer>

@if(openCancelPopup) {
    <app-cancel-ccd-popup [open]="openCancelPopup" [cancelCcdNo]="cancelCcdNo" [cancelPopupSubmitLoading]="cancelPopupSubmitLoading" (onButtonClick)="cancelCcdPopupButtonClick($event)"></app-cancel-ccd-popup>
}

@if(openDeleteDraftPopup) {
    <app-delete-new-ccd-creation [open]="openDeleteDraftPopup" [deletePopupDeleteLoading]="deletePopupDeleteLoading" (onButtonClick)="deleteCcdPopupButtonClick($event)"></app-delete-new-ccd-creation>
}
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule, ModalModule } from 'snl-complib';

@Component({
  selector: 'app-delete-user-popup',
  standalone: true,
  imports: [ModalModule, ButtonModule],
  templateUrl: './delete-user-popup.component.html',
  styleUrl: './delete-user-popup.component.scss'
})
export class DeleteUserPopupComponent {
  modalSettings: any;
  @Input() openDeleteUserPopup: boolean = false;
  @Input() userToDelete:any = {};
  @Input() deletePopupDeleteLoading: boolean =false;
  @Output() onButtonClick: EventEmitter<any | null> = new EventEmitter();

  ngOnInit(){
    this.modalSettings = { showCloseIcon: true, backdropClick: false };
  }

  closeModalPopUp() {
    this.onButtonClick.emit({})
  }

  deleteAndCloseModalPopUp() {
    this.onButtonClick.emit({
      isDeleteUser: true
    });
  }
}

<snl-modal class="CommnModalChanges DeleteModel" [type]="'small'" [open]="openDeleteUserPopup" (modalClose)="closeModalPopUp()" [settings]="modalSettings">
    <div slot="heading">Delete user</div> 
    <div slot="modalContent">
         <p class="body-b1">
            Are you sure you want to delete 
            <strong>{{userToDelete?.name}}?</strong>
        </p>
    </div>
    <div slot="modalFooter">
        <snl-button type="secondary" (click)="closeModalPopUp()" size="large">Cancel</snl-button>
        <snl-button size="large" class="DeleteClr" (click)="deleteAndCloseModalPopUp()">Yes, delete</snl-button>
    </div>
</snl-modal>
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule, FileUploadModule, LinkModule, TooltipModule } from 'snl-complib';
import { FileUploadService } from '../../services/file-upload.service';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { Toasts } from 'src/app/constant/toast.messages';
import { ToasterService } from 'src/app/services/toaster.service';
import { CcdService } from 'src/app/services/ccd.service';
import { FileDragNDropDirective } from 'src/app/directives/file-drag-n-drop.directive';

@Component({
  selector: 'app-upload-files',
  standalone: true,
  imports: [FileUploadModule, CommonModule, ButtonModule, SvgIconComponent, LinkModule, FileDragNDropDirective, TooltipModule],
  templateUrl: './upload-files.component.html',
  styleUrl: './upload-files.component.scss'
})
export class UploadFilesComponent {
  @Input() uploadScreen: string = '';
  @Input() ccdNo: string | null = null;
  @Input() department: string = '';
  @Input() showMarkAsComplete: boolean = false;
  @Input() documents: any = [];
  @Input() documentLoading: boolean = false;
  @Output() startUpload = new EventEmitter<() => void>();
  @Output() uploadedDocuments: EventEmitter<any | null> = new EventEmitter();

  public selectedFiles?: FileList;
  public progressInfos: any[] = [];
  public message: string[] = [];
  public progressClass: string = 'second-progress';
  public uploadSuccessCount: number = 0;
  public uploadFailedCount: number = 0;
  public idx = 0;
  public deletingDocumentId: string = '';
  public downloadingDocumentId: string = '';
  public documentLegendStatus: string = 'no_document' //no_document, document_added
  public fileNameTooltipVisibility:any = {};
  public count:number = 0;

  constructor(
    private ccdService: CcdService,
    public uploadService: FileUploadService,
    private toasterService: ToasterService,
    private el: ElementRef
  ) { }

  ngOnInit() {
    this.getDocumentLegendStatus();
  }

  ngOnChanges() {
    if(this.documents?.length > 0){
      this.documents = this.documents.map((item:any) => {
        return { ...item, docNumber: 'docNumber_'+this.count++ };
      });
      this.setTooltioOnFilename(this.documents);
    }
  }

  getDocumentLegendStatus() {
    this.ccdService.getDepartmentSpecificDocumentLegend().subscribe((event) => {
      this.documentLegendStatus = event?.documentLegendStatus;
    })
  }

  extractFileSize(fileSize: number) {
    if (fileSize < 1) {
      const sizeInKB = fileSize * 1024;
      return `${Math.round(sizeInKB)}Kb`;
    }
    return `${Number(fileSize)?.toFixed(2)}Mb`;
  }
  
  setTooltioOnFilename(documents:any){
    setTimeout(() => {
      documents.forEach((document: any) => {
        const element = this.el.nativeElement.querySelector(`#${document?.docNumber}`);
        if(element){
          const width = element.offsetWidth;
          if (width > 300) {
            this.fileNameTooltipVisibility[document?.docNumber] = true;
          }
          else{
            this.fileNameTooltipVisibility[document?.docNumber] = false;
          }
        }
      });
    }, 1);
  }

  selectFiles(event: any, type: string): void {
    if (type === 'drag') {
      this.selectedFiles = event;
    }
    else if (type === 'upload') {
      this.selectedFiles = event.target.files;
    }

    if (this.selectedFiles && this.uploadScreen === 'create-ccd' && !this.ccdNo) {
      this.startUpload.emit();
    } else if (this.selectedFiles) {
      this.startUploading();
    }
  }

  startUploading(): void {
    if (this.selectedFiles && this.selectedFiles?.length > 0) {
      this.setAutoSaveMessage('saving');
      this.uploadSuccessCount = this.uploadFailedCount = 0;
      let loopLength = this.selectedFiles.length > 15 ? 15 : this.selectedFiles.length;
      for (let i = 0; i < loopLength; i++) {
        this.upload(this.idx, this.selectedFiles[i]);
        this.idx++;
      }
    }
  }

  upload(idx: number, file: File): void {
    this.initializeUpload(idx, file);
    if (!this.isFileValid(file, idx)) {
      return;
    }
    const fileSize = file.size / 1024 / 1024;
    this.uploadFile(file, idx, fileSize);
  }
  
  private initializeUpload(idx: number, file: File): void {
    const fileSize = file ? file.size / 1024 / 1024 : 0;
    this.message[idx] = "Uploading...";
    this.progressInfos[idx] = {
      value: 0,
      fileName: file.name,
      status: "Uploading...",
      fileSize: fileSize,
    };
  }
  
  private isFileValid(file: File, idx: number): boolean {
    const allowedFileTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/pdf",
      "image/png",
      "image/jpeg",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const fileSize = file.size / 1024 / 1024;
    if (!allowedFileTypes.includes(file.type)) {
      this.setUploadError(idx, "Invalid file type. Only PDF, DOCX, XLSX, JPG, and PNG are allowed.");
      return false;
    }
    if (fileSize <= 0 || fileSize > 10) {
      this.setUploadError(idx, "File size exceeds the 10 MB limit.");
      return false;
    }
    return true;
  }

  private setUploadError(idx: number, errorMessage: string): void {
    this.message[idx] = errorMessage;
    this.progressInfos[idx].status = "Failed";
    this.progressInfos[idx].docNumber = "docNumber_" + this.count++;
    this.progressClass = "second-progress-failed";
    this.checkAllUploadsComplete("failed");
    this.setTooltioOnFilename([this.progressInfos[idx]]);
  }
  private uploadFile(file: File, idx: number, fileSize: number): void {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = (reader.result as string).split(",")[1];
      const payload = this.buildPayload(file, base64String, fileSize);
  
      this.uploadService.upload(payload).subscribe({
        next: (event: any) => this.handleUploadEvent(event, idx),
        error: (err: any) => this.setUploadError(idx, err?.error?.reason || "Upload failed."),
      });
    };
    reader.readAsDataURL(file);
  }
  
  private buildPayload(file: File, base64String: string, fileSize: number): any {
    return {
      attachment: {
        fileName: file.name,
        content: base64String,
        fileSize: fileSize,
        fileType: file.type,
      },
      ccdNo: this.ccdNo,
      department: this.department,
    };
  }
  
  private handleUploadEvent(event: any, idx: number): void {
    if (event.type === HttpEventType.UploadProgress) {
      this.updateProgress(event, idx);
    } else if (event instanceof HttpResponse) {
      this.finalizeUpload(event, idx);
    }
  }
  
  private updateProgress(event: any, idx: number): void {
    let progressValue = Math.round(100 * event.loaded / event.total);
    let progressValueRandom = this.getRandomInt(10);
    let progressValueBelow100 = 100 - progressValueRandom;
    this.progressInfos[idx].value = (progressValue < progressValueBelow100) ? progressValue : progressValueBelow100;
  }
  
  private finalizeUpload(event: HttpResponse<any>, idx: number): void {
    this.progressInfos[idx].value = 100;
    this.progressInfos[idx].status = "Uploaded";
    this.progressInfos[idx].docId = event.body?.docId;
    this.progressInfos[idx].docNumber = "docNumber_" + this.count++;
    this.message[idx] = "Uploaded";
    this.progressClass = "second-progress-done";
    this.checkAllUploadsComplete("success");
    if (this.uploadScreen === "approve") {
      this.uploadedDocuments.emit({ docId: event.body?.docId });
    }
    if (["approve", "review"].includes(this.uploadScreen)) {
      this.updateDocumentAddedLegendStatus();
    }
    this.setTooltioOnFilename([this.progressInfos[idx]]);
  }

  updateDocumentAddedLegendStatus() {
    if (this.documentLegendStatus === 'no_document') {
      this.documentLegendStatus = 'document_added';
      const reqObj = {
        ccdNo: this.ccdNo,
        action: "DOCUMENT",
        department: this.department,
      };
      this.ccdService.updateLegend(reqObj).subscribe({
        next: () => {
          this.ccdService.setUpdatedDocumentLegend({ department: this.department, documentStatus: 'document_added' });
        },
        error: (err: any) => {
          console.error('Error updating Document legend:', err);
        }
      });
    }
  }

  checkAllUploadsComplete(type: string): void {
    if (type === 'success') {
      this.uploadSuccessCount++;
    }
    else if (type === 'failed') {
      this.uploadFailedCount++;
    }
    const totalUploadCount = this.uploadSuccessCount + this.uploadFailedCount;
    if (totalUploadCount === this.selectedFiles?.length) {
      if (this.uploadSuccessCount === this.selectedFiles?.length) {
        this.setAutoSaveMessage('saved');
      }
      else {
        this.setAutoSaveMessage('not-saved');
      }
    }
  }

  setAutoSaveMessage(type: string) {
    if (this.uploadScreen === 'create-ccd') {
      this.ccdService.setCreateCcdStep1ScreenAutoSaveMessage(type);
    }
    else if (this.uploadScreen === 'review') {
      this.ccdService.setReviewScreenAutoSaveMessage(type);
    }
  }

  getRandomInt(max: number) {
    let randomValue = Math.floor(Math.random() * max);
    return (randomValue === 0) ? 1 : randomValue;
  }

  deleteFile(docId: string, isItJustUploaded: boolean, isDelete: boolean = false) {
    if (!isDelete) {
      this.removeFileFromJustUploadedList(docId);
      return;
    }
    const reqobj = {
      docId: docId
    }
    this.deletingDocumentId = docId;
    this.setAutoSaveMessage('saving');
    this.uploadService.deleteFile(reqobj).subscribe({
      next: ({ docId }: any) => {
        if (isItJustUploaded) {
          this.removeFileFromJustUploadedList(docId);
        }
        else {
          this.documents = this.documents.filter((doc: any) => {
            return doc?.docId !== docId;
          })
        }
        const justUploadedFiles = this.progressInfos.filter((doc: any) => doc?.status === 'Uploaded');
        if (this.documents?.length === 0 && justUploadedFiles?.length === 0) {
          this.updateNoDocumentLegendStatus();
        }
        this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.DeleteDocument.Success, Toasts.Actions.DeleteDocument.Title);
        this.deletingDocumentId = '';
        this.setAutoSaveMessage('saved');
      },
      error: (error) => {
        if (isDelete) {
          if (error?.status === 424) {
            this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.reason);
          }
          else {
            this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.DeleteDocument.Error);
          }
        }
        else {
          this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        }
        this.deletingDocumentId = '';
        this.setAutoSaveMessage('not-saved');
        console.error('Error deleteing file:', error);
      }
    });
  }

  removeFileFromJustUploadedList(docId: string) {
    this.progressInfos = this.progressInfos.filter((doc: any) => {
      if (doc?.docId !== docId) {
        return doc;
      }
      else {
        this.idx--;
      }
    })
  }

  updateNoDocumentLegendStatus() {
    if (this.documentLegendStatus === 'document_added') {
      this.documentLegendStatus = 'no_document';
      const reqObj = {
        ccdNo: this.ccdNo,
        action: "NO_DOCUMENT",
        department: this.department
      };
      this.ccdService.updateLegend(reqObj).subscribe({
        next: () => {
          this.ccdService.setUpdatedDocumentLegend({ department: this.department, documentStatus: 'no_document' });
        },
        error: (err: any) => {
          console.error('Error updating Document legend:', err);
        }
      });
    }
  }

  download(docId: string) {
    this.downloadingDocumentId = docId;
    this.uploadService.downloadFile(docId, 'view').subscribe({
      next: (response: HttpResponse<Blob>) => {
        const blob = response.body as Blob;

        // Extract Content-Disposition header
        const contentDisposition = response.headers.get('Content-Disposition') || '';

        // Extract the file name
        const fileName = this.getFileNameFromDisposition(contentDisposition) || 'downloaded-file.pdf';
        const mimeType = this.getMimeTypeFromDisposition(contentDisposition);

        const viewableMimeTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'];
        const isViewable = viewableMimeTypes.includes(mimeType);
        if (isViewable) {
          this.downloadingDocumentId = '';
          this.openFileInNewTab(blob, fileName);
          return;
        }

        // Create a link and trigger download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = this.uploadService.extractFileName(fileName);
        link.click();

        // Cleanup
        window.URL.revokeObjectURL(url);
        this.downloadingDocumentId = '';
      },
      error: (error) => {
        this.downloadingDocumentId = '';
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.DownloadDocument.Error);
        console.error('Error downloading file:', error);
      },
    });
  }

  openFileInNewTab(blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = this.uploadService.extractFileName(fileName);
    window.open(url, '_blank');
  }

  getFileNameFromDisposition(disposition: string): string | null {
    const matches = /filename="?([^"]+)"?/.exec(disposition);
    return matches?.[1] || null;
  }

  getMimeTypeFromDisposition(disposition: string): string {
    let mimeType = disposition.split('; mimetype=')[1]
    return mimeType || 'application/octet-stream';
  }
}

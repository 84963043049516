<section class="MainCard">
    <div class="stickyTabsAndButton">
        <div class="TabsHolder">
            @for (tab of userTabs; track $index) {
            <snl-page-tab [isSelected]="selectedTab === tab?.key" (onPageTabClick)="onTabSelect(tab.key)">{{tab?.value}}</snl-page-tab>
            }
        </div>
        <div class="AddNewUser">
            <snl-button [type]="'secondary'" [size]="'large'" [disabled]="usersDataLoading" (buttonClick)="openAddUserModal()" class="BtnSkeleton" [ngClass]="{'Stolt-Skeleton':usersDataLoading}">
                <svg-icon src="assets/icons/plus.svg"></svg-icon>
                Add new user
            </snl-button>
        </div>
    </div>
    <div class="ccd-table">
        <snl-table class="TabelAddUser" class="{{usersDataLoading ? 'TableSkeleton' : ''}}" [useTopScroll]="true">
            <snl-row class="bg-theader">
                <snl-th class="HeightManager">
                    <span>Name/ Initial</span>
                </snl-th>
                <snl-th class="HeightManager">
                    <span>Role</span>
                </snl-th>
                <snl-th class="HeightManager">
                    <span>Department</span>
                </snl-th>
                <snl-th class="HeightManager">
                    <span>Management</span>
                </snl-th>
                <snl-th class="HeightManager">
                    <span>Owner</span>
                </snl-th>
                <snl-th class="HeightManager">
                    <span>Admin</span>
                </snl-th>
                <snl-th class="HeightManager">
                    <span>Department edit</span>
                </snl-th>
                <snl-th class="HeightManager">
                    <span>View only</span>
                </snl-th>
                <snl-th class="HeightManager">
                        
                </snl-th>
            </snl-row>
            @if(usersDataLoading) {
                @for (_ of [].constructor(10); track $index) {
                <snl-row class="ColWidthMain">
                    @for (_ of [].constructor(9); track $index) {
                    <snl-td><span class="Stolt-Skeleton"></span></snl-td>
                    }
                </snl-row>
                }
            }
            @else {
                @if(usersData?.length === 0) {
                <snl-row class="ColWidthMain">
                    <snl-td class="NoDataFoundCenter">
                        <span class="body-b1">You don’t have any users added yet.</span>
                    </snl-td>
                </snl-row>
                }
                @else{
                    @for (userData of usersData; track $index) {
                    <snl-row class="ColWidthMain">
                        <snl-td class="InitialName"><span (click)="openEditUserModal(userData)">{{userData?.name}}</span></snl-td>
                        <snl-td>{{userData?.designation || '-'}}</snl-td> 
                        <snl-td>{{divisions[userData?.division] || userData?.division}}</snl-td> 
                        <snl-td>{{userData?.role === '1' ? 'Yes' : 'No'}}</snl-td> 
                        <snl-td>{{userData?.role === '2' ? 'Yes' : 'No'}}</snl-td>
                        <snl-td>{{userData?.isAdmin ? 'Yes' : 'No'}}</snl-td> 
                        <snl-td>{{userData?.role === '3' && userData?.departmentAccess === 'EDIT' ? 'Yes' : 'No'}}</snl-td>
                        <snl-td>{{userData?.role === '4' || userData?.departmentAccess === 'VIEW'  ? 'Yes' : 'No'}}</snl-td> 
                        <snl-td><svg-icon (click)="openDeleteUserModal(userData)" class="trash_icn" src="assets/icons/trash.svg"></svg-icon></snl-td>
                    </snl-row>
                    }
                }
            }
        </snl-table>
    </div>
</section>

@if (openAddUserPopup) {
<app-add-user-popup [openAddUserPopup]="openAddUserPopup" [userDataForPopulation]="userDataForPopulation" (onButtonClick)="closeAddUserModal($event)"></app-add-user-popup>
}
@if (openDeleteUserPopup) {
<app-delete-user-popup [openDeleteUserPopup]="openDeleteUserPopup" [userToDelete]="userToDelete" (onButtonClick)="deleteUser($event)"></app-delete-user-popup>
}
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare const gtag: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(private _router: Router) {
    this._router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(event => {
        gtag('js', new Date());
        gtag('config', environment.GOOGLE_ANALYTICS.googleAnalyticKey);
      });
  }

  init() {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.GOOGLE_ANALYTICS.googleAnalyticKey}`;
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);

    const gtagEl = document.createElement('script');
    const gtagBody = document.createTextNode(`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
    `);
    gtagEl.appendChild(gtagBody);
    document.body.appendChild(gtagEl);
  }

  logEvent(event: any, category: any, label: any, value: any) {
    gtag('event', event, {
      event_category: category,
      event_label: label,
      value: value
    });
  }
}
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonModule, PillsModule } from 'snl-complib';
import { TYPEOFCOA } from 'src/app/constant/constant';
import { ApprovePopupComponent } from '../approve-popup/approve-popup.component';
import { CcdService } from 'src/app/services/ccd.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { Toasts } from 'src/app/constant/toast.messages';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-preview-ccd-details',
  standalone: true,
  imports: [CommonModule, ButtonModule, SvgIconComponent, ApprovePopupComponent, PillsModule],
  templateUrl: './preview-ccd-details.component.html',
  styleUrl: './preview-ccd-details.component.scss'
})
export class PreviewCcdDetailsComponent {
  public openApproveCcdPopup: boolean = false;
  public typeOfCoa: any = TYPEOFCOA;
  public isCcdApproved: boolean = false;
  public downloadingDocumentId: string = '';
  @Input() ownerAddedDocuments:any = [];
  @Input() ccdRes: any;
  @Input() fetchDataLoading: boolean = true;
  @Input() showEditButton: boolean = false;
  @Input() editVisibility: boolean = false;
  @Input() editButtonRoute: string = '';
  @Input() showApproveButton: boolean = false;
  @Input() isManagementTabOpen: boolean = false;
  @Input() disableApproveButton: boolean = true;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef,
    private ccdService: CcdService,
    public uploadService: FileUploadService,
    private toasterService: ToasterService
  ) { }
  
  ngOnChanges() {
    if(Object.keys(this.ccdRes)?.length > 0){
      setTimeout(() => {
        this.truncateText();
      },10);
      
      this.ccdService.getCcdApproveStatus().subscribe((data: any) => {
        this.isCcdApproved = data?.value;
      });
    }
  }

  truncateText(): void {
    const ids = [
      'coaType', 'dueBy', 'customer', 'contractPeriod', 'lob', 'service', 'product',
      'estAnnualVolume', 'estAnnualFreight', 'chartererPartyForm', 'law', 'manager'
    ];
    ids.forEach(id => {
      const element = this.el.nativeElement.querySelector(`#${id}`);
      const width = element.offsetWidth;

      if (width > 400) {
        this.renderer.addClass(element, 'truncate-text');
      }
    });
  }

  extractLob(lob: string[]): string {
    if (!Array.isArray(lob) || lob.length === 0) return '';
    const result = lob
      .map(item => item.match(/\(([^)]+)\)/)?.[1])
      .join(', ');
    return result;
  }
  
  editDetails() {
    this.router.navigateByUrl(this.editButtonRoute);
  }

  openApprovePopup(){
    this.openApproveCcdPopup = true;
  }
  submitApproveCcdForm(event: any){
    this.openApproveCcdPopup = false;
    if(event?.isCcdApproved){
      this.ccdService.setCcdApproveStatus({value: event?.isCcdApproved, from: 'approvePopup'});
    }
  }

  downloadFile(docId: string) {
    this.downloadingDocumentId = docId;
    this.uploadService.downloadFile(docId).subscribe({
      next: (response: HttpResponse<Blob>) => {
        const blob = response.body as Blob;
  
        // Extract Content-Disposition header
        const contentDisposition = response.headers.get('Content-Disposition') || '';
  
        // Extract the file name
        const fileName = this.getFileNameFromDisposition(contentDisposition) || 'downloaded-file.pdf';
  
        // Create a link and trigger download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = this.uploadService.extractFileName(fileName);
        link.click();
  
        // Cleanup
        window.URL.revokeObjectURL(url);
        this.downloadingDocumentId = '';
      },
      error: (error) => {
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.DownloadDocument.Error);
        this.downloadingDocumentId = '';
        console.error('Error downloading file:', error);
      },
    });
  }

  getFileNameFromDisposition(disposition: string): string | null {
    const matches = /filename="?([^"]+)"?/.exec(disposition);
    return matches?.[1] || null;
  }
}

@if (ccdRes) {
<div class="InfoCardLft">
    <!-- Preview Details  -->
    <h2 class="sub-heading-s1 EditBtn">
        Preview - CCD details
        @if(editVisibility) {
        <snl-button [type]="'tertiary'" [size]="'small'" [icon]="'fa fa-pencil'" (buttonClick)="editDetails()" [disabled]="!showEditButton ">
            Edit
        </snl-button>
        }
    </h2> 
        <ul class="OptionsList">
        <li>
            <span class="body-b LeftCell">Type of COA</span>
            <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">
                <span id="coaType">{{ typeOfCoa[ccdRes?.coaType] }}</span>
            </span>
        </li>
        <li>
            <span class="body-b LeftCell">Due date</span>
            <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">
                <span id="dueBy">{{ccdRes?.dueBy | date: 'dd MMM yyyy' }}</span>
            </span>
        </li>
        <li>
            <span class="body-b LeftCell">Counterparty</span>
            <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">
                <span id="customer">{{ccdRes?.customer}}</span>
            </span>
        </li>
        <li>
            <span class="body-b LeftCell">Contract period</span>
            <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">
                <span id="contractPeriod">{{ccdRes?.coaStartDate | date: 'dd MMM yyyy' + ' -' }} {{ccdRes?.coaEndDate | date: 'dd MMM yyyy' }}</span>
            </span>
        </li>
        <li>
            <span class="body-b LeftCell">Line of business</span>
            <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">
                <span id="lob">
                    {{extractLob(ccdRes?.lob)}}
                </span>
            </span>
        </li>
        <li>
            <span class="body-b LeftCell">Service</span>
            <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">
                <span id="service">{{ccdRes?.service}}</span>
            </span>    
        </li>
        <li>
            <span class="body-b LeftCell">Product</span>
            <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">
                <span id="product">{{ccdRes?.product}}</span>
            </span>    
        </li>
        <li>
            <span class="body-b LeftCell">Estimated annual volume (MT)</span>
            <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">
                <span id="estAnnualVolume" *ngIf="!fetchDataLoading">{{ccdRes?.estAnnualVolumeMin?.toLocaleString()}} - {{ccdRes?.estAnnualVolumeMax?.toLocaleString()}}</span>
            </span>    
        </li>
        <li>
            <span class="body-b LeftCell">Estimated annual freight ($)</span>
            <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">
                <span id="estAnnualFreight" *ngIf="!fetchDataLoading">{{ccdRes?.estAnnualFreightMin?.toLocaleString()}} - {{ccdRes?.estAnnualFreightMax?.toLocaleString()}}</span>
            </span>    
        </li>
        <li>
            <span class="body-b LeftCell">Charter party form</span>
            <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">
                <span id="chartererPartyForm">{{ccdRes?.chartererPartyForm}}</span>
            </span>    
        </li>
        <li>
            <span class="body-b LeftCell">Law</span>
            <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">
                <span id="law">{{ccdRes?.law}}</span>
            </span>    
        </li>
        <li>
            <span class="body-b LeftCell">Manager</span>
            <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">
                <span id="manager" class="ApproveBtn">
                    {{ccdRes?.manager}}
                    @if (isCcdApproved && isManagementTabOpen) {
                    <snl-pills [type]="'outline'" [layout]="'success'">Approved</snl-pills>
                    }
                    @else if (showApproveButton) {
                        <snl-button [disabled]="disableApproveButton" (buttonClick)="openApprovePopup()" [type]="'primary'" [size]="'small'">
                            Approve
                        </snl-button>
                    }
                </span>
            </span>    
        </li>
    </ul> 

    <!-- Document(s) -->
        <h2 class="sub-heading-s1">Document(s)</h2> 
        <ul class="OptionsList DocumentList body-b1"> 
        @if (ownerAddedDocuments?.length > 0) {
            @for (document of ownerAddedDocuments; track $index) {
            <li>
                <span class="body-b1 truncate-text">{{uploadService.extractFileName(document?.fileName)}}</span>
                @if (downloadingDocumentId === document?.docId) {
                <svg-icon class="df-c rotating-icon" src="assets/icons/loader-icon.svg"></svg-icon>
                }
                @else{
                <svg-icon (click)="downloadFile(document?.docId)" class="df-c" src="assets/icons/download.svg"></svg-icon>
                }
            </li>
            }
        }
        @else {
            <span class="body-b1">NA</span>
        }
    
    </ul> 

    <!-- CCD reference -->
        <h2 class="sub-heading-s1">CCD reference</h2> 
    <!-- Validated Reference CCD  -->
    @if(ccdRes?.ccdRefField)
    {
    <div class="ValidatedMsg body-b1">
        <svg-icon class="df-c" src="assets/icons/completed.svg"></svg-icon>
        <span>CCD# <strong>{{ccdRes?.ccdRefField}}</strong></span> 
    </div>
    }
    @else{
        <span class="body-b1">NA</span>
    }
</div>
    @if(openApproveCcdPopup) {
    <app-approve-popup [open]="openApproveCcdPopup" [ccdData]="ccdRes" (onButtonClick)="submitApproveCcdForm($event)"></app-approve-popup>
    }
}
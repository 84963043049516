import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule, ModalModule } from 'snl-complib';

@Component({
  selector: 'app-activate-department',
  standalone: true,
  imports: [ModalModule,ButtonModule, NgClass],
  templateUrl: './activate-department.component.html',
  styleUrl: './activate-department.component.scss'
})
export class ActivateDepartmentComponent {
  disableSaveButton:boolean = true;
  modalSettings: any;

  @Input() open: boolean = false;
  @Input() activateDepartmentSubmitLoading: boolean =false;
  @Output() onButtonClick: EventEmitter<any | null> = new EventEmitter();

  ngOnInit(){
    this.modalSettings = { showCloseIcon: true, backdropClick: false };
  }

  closeModalPopUp() {
    this.onButtonClick.emit({}) 
  }

  activateAndCloseModalPopUp() {
      this.onButtonClick.emit({
        isActivateDepartment: true
      });
  }
}
